/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-16
 */

// Add third-party dependencies.
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Box, Typography, Paper, Button } from '@mui/material';

/**
 * NotFound component.
 * @example
 * return (
 *   <NotFound />
 * )
 * @returns {React.ReactElement} component.
 */
const NotFound = () => {
  // Add theme hooks.
  const theme = useTheme();

  // Set styles.
  const styles = {
    text: { fontSize: '2rem', textAnchor: 'middle', fill: theme.palette.common.white },
    leftCircle: { fill: theme.palette.primary.dark, opacity: 0.5 },
    rightCircle: { fill: theme.palette.info.dark, opacity: 0.5 },
  };

  // Render component.
  return (
    <Box>
      <Box sx={{ margin: 2 }}>
        <Typography variant="h2" component="h1">
          Error 404
        </Typography>
        <Typography variant="h3" component="h2">
          Page Not Found
        </Typography>
      </Box>
      <Box align="center" sx={{ margin: 2 }}>
        <Button variant="contained" color="primary" component={RouterLink} to="/">
          Please take me home
        </Button>
      </Box>
      <Paper sx={{ padding: 2 }}>
        <Box align="center" sx={{ margin: 2 }}>
          <Typography variant="h3">Venn Diagram</Typography>
        </Box>
        <Box align="center" sx={{ margin: 2 }}>
          <Typography gutterBottom>
            Venn diagrams were introduced in 1880 by John Venn in a paper entitled &ldquo;On the Diagrammatic and
            Mechanical Representation of Propositions and Reasonings&rdquo; in the Philosophical Magazine and Journal
            of Science, about the different ways to represent propositions by diagrams.
          </Typography>
        </Box>
        <Box align="center">
          <Box component="svg" sx={{ width: 600, height: 400 }}>
            <Box component="circle" cx="200" cy="200" r="200" sx={styles.leftCircle} />
            <Box component="circle" cx="400" cy="200" r="200" sx={styles.rightCircle} />
            <Box component="text" x="100" y="200" dy="-5" sx={styles.text}>
              Something
            </Box>
            <Box component="text" x="100" y="200" dy="30" sx={styles.text}>
              Broke
            </Box>
            <Box component="text" x="300" y="200" dy="-5" sx={styles.text}>
              404
            </Box>
            <Box component="text" x="300" y="200" dy="30" sx={styles.text}>
              Page
            </Box>
            <Box component="text" x="500" y="200" dy="-5" sx={styles.text}>
              You Can&apos;t
            </Box>
            <Box component="text" x="500" y="200" dy="30" sx={styles.text}>
              Type
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

// Export component.
export default NotFound;
