/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-16
 */

// Add third-party dependencies.
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Box, Grid, Link as MuiLink, Typography } from '@mui/material';

// Add local dependencies.
import Icons from '../Common/Icons';
import ChatWidget from '../Common/ChatWidget';

/**
 * Footer component.
 * @example
 * return (
 *   <Footer />
 * )
 * @returns {React.ReactElement} component.
 */
const Footer = () => {
  // Get theme.
  const theme = useTheme();

  // Set component styles.
  const styles = {
    link: {
      color: theme.palette.primary.contrastText,
      '&:hover': { color: theme.palette.secondary.contrastText },
    },
    icon: { display: 'inline-flex', alignSelf: 'center', top: '.3em', position: 'relative' },
    socialMediaIcon: {
      display: 'inline-flex',
      alignSelf: 'center',
      top: '.3em',
      position: 'relative',
      fontSize: '2em',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:hover': {
        color: theme.palette.secondary.contrastText,
      },
    },
    chatContainer: {
      textAlign: 'right',
      '& #lc_text-widget--btn': { backgroundColor: 'red' },
    },
  };

  // Current date.
  const currentDate = new Date();
  const fullYear = currentDate.getFullYear();

  // Render component.
  return (
    <Box
      component="footer"
      sx={{
        marginTop: 'auto',
        color: theme.palette.primary.contrastText,
        bgcolor: theme.palette.primary.main,
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} md="auto" sx={{ textAlign: 'center' }}>
              <MuiLink component={RouterLink} to="/location" sx={styles.link}>
                <Box sx={styles.icon}>
                  <Icons iconName="Map" />
                  8766 Corbin Avenue, Northridge, CA 91324-3307
                </Box>
              </MuiLink>
            </Grid>
            <Grid item xs={12} md="auto" sx={{ textAlign: 'center' }}>
              <MuiLink sx={styles.link} color="inherit" href="tel:+1-818-654-4140">
                <Box sx={styles.icon}>
                  <Icons iconName="Phone" /> 1-818-654-4140
                </Box>
              </MuiLink>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs="auto">
              <MuiLink
                color="inherit"
                href="https://www.facebook.com/relevestudios"
                aria-label="Go to our Facebook page"
              >
                <Box sx={styles.socialMediaIcon}>
                  <Icons iconName="Facebook" />
                </Box>
              </MuiLink>
            </Grid>
            <Grid item xs="auto">
              <MuiLink
                color="inherit"
                href="https://www.instagram.com/relevestudios"
                aria-label="Go to our Instagram page"
              >
                <Box sx={styles.socialMediaIcon}>
                  <Icons iconName="Instagram" />
                </Box>
              </MuiLink>
            </Grid>
            <Grid item xs="auto">
              <MuiLink color="inherit" href="https://twitter.com/relevestudios" aria-label="Go to our Twitter page">
                <Box sx={styles.socialMediaIcon}>
                  <Icons iconName="Twitter" />
                </Box>
              </MuiLink>
            </Grid>
            <Grid item xs="auto">
              <MuiLink color="inherit" href="https://vimeo.com/relevestudios" aria-label="Go to our Vimeo channel">
                <Box sx={styles.socialMediaIcon}>
                  <Icons iconName="Vimeo" />
                </Box>
              </MuiLink>
            </Grid>
            <Grid item xs="auto">
              <MuiLink
                color="inherit"
                href="https://www.youtube.com/relevestudios"
                aria-label="Go to our YouTube channel"
              >
                <Box sx={styles.socialMediaIcon}>
                  <Icons iconName="YouTube" />
                </Box>
              </MuiLink>
            </Grid>
            <Grid item xs="auto">
              <MuiLink
                color="inherit"
                href="https://www.yelp.com/biz/relev%C3%A9-studios-northridge"
                aria-label="Go to our Yelp reviews"
              >
                <Box sx={styles.socialMediaIcon}>
                  <Icons iconName="Yelp" />
                </Box>
              </MuiLink>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs="auto">
              <Typography variant="caption" color="inherit">
                <MuiLink component={RouterLink} to="/privacy-policy" sx={styles.link}>
                  Privacy Policy
                </MuiLink>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="caption" color="inherit">
                <MuiLink component={RouterLink} to="/terms-of-service" sx={styles.link}>
                  Terms of Service
                </MuiLink>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} align="center" direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="caption">
                <Box sx={styles.icon}>
                  <Icons iconName="Copyright" sx={styles.icon} /> Little Shilling, Inc. 2007-{fullYear}. All rights
                  reserved.
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={styles.chatContainer} item xs={1}>
          <ChatWidget />
        </Grid>
      </Grid>
    </Box>
  );
};

// Export component.
export default Footer;
