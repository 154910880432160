/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-23
 */

// Add third-party dependencies.
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, ButtonGroup, Button, Menu, MenuItem } from '@mui/material';
import AuthContext from '../../context/AuthContext';

/**
 * AuthMenuGroup component.
 * @example
 * return (
 *   <AuthMenuGroup />
 * )
 * @returns {React.ReactElement} component.
 */
const AuthMenuGroup = ({ children }) => {
  // Get user information from context.
  const { user } = useContext(AuthContext);

  // Set navigate hooks.
  const navigate = useNavigate();

  // Set state hooks.
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    menuId: null,
  });

  const open = Boolean(menuState.anchorEl);

  // Handle click event for menus.
  const handleClick = (event, menuId) => {
    setMenuState({
      anchorEl: event.currentTarget,
      menuId,
    });
  };

  // Handle close event for menus.
  const handleClose = () => {
    setMenuState({
      anchorEl: null,
      menuId: null,
    });
  };

  // Menu configurations.
  const menus = {
    accountMenu: [
      { label: 'Welcome', path: '/welcome' },
      { label: 'Profile', path: '/profile' },
      { label: 'Change Password', path: '/change-password' },
      { label: 'Change Username', path: '/change-username' },
      { label: 'Logout', path: '/logout' },
    ],
    employMenu: [{ label: 'Timesheet', path: '/timesheet' }],
    payrollMenu: [
      { allowedGroups: ['Payroll', 'Owners'], label: 'Report', path: '/payroll-report' },
      { allowedGroups: ['Payroll', 'Owners'], label: 'Edit Timesheets', path: '/edit-timesheets' },
      { allowedGroups: ['Payroll', 'Owners'], label: 'Summary', path: '/payroll-summary' },
      { allowedGroups: ['Payroll', 'Owners'], label: 'Desk Activity', path: '/desk-activity' },
      { allowedGroups: ['Payroll', 'Owners'], label: 'Admin Pay', path: '/admin-pay' },
    ],
    // Add more menus here as needed.
  };

  // Filter out menus if the user is not in the allowed groups.
  const filteredMenus = {};
  Object.keys(menus).forEach((menuId) => {
    filteredMenus[menuId] = menus[menuId].filter((item) => {
      // If no allowedGroups is specified, the item is accessible.
      if (!item.allowedGroups) return true;
      // Check if any of the user's groups are in the allowedGroups.
      return item.allowedGroups.some((group) => user?.groups?.includes(group));
    });
    // If no items remain after filtering, remove the menu
    if (filteredMenus[menuId].length === 0) delete filteredMenus[menuId];
  });

  // Render component.
  return (
    <Box px={1}>
      <ButtonGroup variant="contained" aria-label="Auth button group">
        {Object.keys(filteredMenus).map((menuId) => {
          const name = menuId.replace('Menu', '');
          const id = `${name}-button`;
          const menuLabel = `${name}Menu`;
          const menulabelId = `${name.toLowerCase()}-menu`;
          const label = name.charAt(0).toUpperCase() + name.slice(1);
          return (
            <Button
              key={menuId}
              id={id}
              aria-controls={open && menuState.menuId === menuLabel ? menulabelId : undefined}
              aria-haspopup="true"
              aria-expanded={open && menuState.menuId === menuLabel ? 'true' : undefined}
              onClick={(event) => handleClick(event, menuLabel)}
            >
              {label}
            </Button>
          );
        })}
      </ButtonGroup>
      {Object.keys(filteredMenus).map((menuId) => (
        <Menu
          key={menuId}
          id={menuId}
          anchorEl={menuState.anchorEl}
          open={open && menuState.menuId === menuId}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': `${menuId}-button`,
          }}
        >
          {filteredMenus[menuId].map((item) => (
            <MenuItem key={item.label} onClick={() => navigate(item.path)}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      ))}
      <Box p={1}>{children}</Box>
    </Box>
  );
};

// Set component property types.
AuthMenuGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

// Export component.
export default AuthMenuGroup;
