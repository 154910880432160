/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-23
 */

/**
 * Get today's date.
 * @returns {String} Today's date.
 */
const getTodaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

/**
 * Calculates the time since a given date.
 * @param {Date} dateTime - The date and time to calculate from.
 * @returns {object} The time since the given date.
 */
const timeSince = (dateTime) => {
  const now = new Date();
  const diff = Math.abs(now - dateTime);
  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return { years, months, days, hours, minutes, seconds };
};

/**
 * Calculate the difference between two dateTimes.
 * @param {Date} dateTime1 - The first date and time.
 * @param {Date} dateTime2 - The second date and time.
 * @returns {object} The difference between the two dateTimes.
 */
const timeDiff = (dateTime1, dateTime2) => {
  const diff = Math.abs(dateTime1 - dateTime2);
  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return { years, months, days, hours, minutes, seconds };
};

/**
 * Round down a time to the nearest half hour.
 * @param {Date} time - The time to round down.
 * @returns {String} The rounded down time.
 */
const roundDownTimeToHalfHour = (time) => {
  const minutes = time.getMinutes() >= 30 ? 30 : 0;
  time.setMinutes(minutes, 0, 0);
  const hours = String(time.getHours()).padStart(2, '0');
  const mins = String(time.getMinutes()).padStart(2, '0');
  return `${hours}:${mins}`;
};

/**
 * Add days to a given date.
 * @param {Date} date - The date to add days to.
 * @param {number} daysToAdd - The number of days to add.
 * @returns {String} The new date.
 */
const addDays = (date, daysToAdd) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + daysToAdd);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

/**
 * Add hours to a given time.
 * @param {Date} time - The time to add hours to.
 * @param {number} hoursToAdd - The number of hours to add.
 * @returns {Date} The new time.
 */
const addHours = (time, hoursToAdd) => {
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date();
  date.setHours(hours + hoursToAdd, minutes);
  return date.toTimeString().substring(0, 5); // Returns "HH:MM"
};

/**
 * Calculate the hours between two times.
 * @param {String} startTime - The start time.
 * @param {String} endTime - The end time.
 * @returns {String} The hours between the two times.
 */
const calculateHours = (startTime, endTime) => {
  const start = new Date(`1970-01-01T${startTime}`);
  const end = new Date(`1970-01-01T${endTime}`);
  const hours = (end - start) / 1000 / 3600; // Convert milliseconds to hours
  return hours.toFixed(2); // Return hours with 2 decimal places
};

/**
 * Parse a date string as local time.
 * @param {String} dateString - The date string to parse.
 * @returns {Date} The parsed date.
 */
const parseDateAsLocal = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

/**
 * Parse a date and time string as local time.
 * @param {String} dateTimeString - The date and time string to parse.
 * @returns {Date} The parsed date and time.
 */
const parseDateTimeAsLocal = (dateTimeString) => {
  const [datePart, timePart] = dateTimeString.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes] = timePart.split(':').map(Number);
  const [seconds] = timePart.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

// Export the functions.
export {
  getTodaysDate,
  timeSince,
  timeDiff,
  roundDownTimeToHalfHour,
  addDays,
  addHours,
  calculateHours,
  parseDateAsLocal,
  parseDateTimeAsLocal,
};
