/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-12
 */

// Add third-party dependencies.
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import { Box, Typography, Unstable_Grid2 as Grid, Paper, IconButton, Link as MuiLink } from '@mui/material';

// Add local dependencies.
import Icons from '../Common/Icons';
import whims from '../../assets/images/whims.png';

/**
 * About component.
 * @example
 * return (
 *   <About />
 * )
 * @returns {React.ReactElement} component.
 */
const About = () => {
  const audio = new Audio('//ssl.gstatic.com/dictionary/static/sounds/20180430/releve--1_us_2.mp3');

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1" gutterBottom>
        About Relev&eacute; Studios
      </Typography>
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
        <Grid xs={12} sm={8}>
          <Typography variant="h6" component="h2" m={1}>
            Relev&eacute; means to lift up...
          </Typography>
          <Typography m={1}>
            ...and that&apos;s what we do. We lift up our members. Our goal is to foster a love for creating and
            performing while building skills and confidence. We are delighted to offer this private rehearsal venue for
            our members. We hope to add value and growth to our community.
          </Typography>
          <Typography variant="signature" m={1}>
            Jodi Shilling
          </Typography>
          <Typography m={1}>Artistic Director/Owner</Typography>
          <Paper sx={{ marginTop: 3, padding: 1 }}>
            <Typography variant="h3" gutterBottom component="h2">
              Mission Statement
            </Typography>
            <Typography>
              Relev&eacute; Studios aims to nurture creative minds with enthusiasm and expertise, helping clients
              develop professionalism, versatility, and proficiency in all aspects of the performing arts, encouraging
              them to strive for excellence both in spirit and in their artistic endeavors.
            </Typography>
            <Typography variant="h6" component="h3">
              Relev&eacute;
              <IconButton onClick={() => audio.play()} aria-label="Play pronunciation">
                <Icons iconName="RecordVoiceOver" />
              </IconButton>{' '}
              (rel-<i>uh</i>-<b>vay</b>)
            </Typography>
            <Box mb={-2}>Definition:</Box>
            <ul>
              <li>Meaning raised, lifted up or relifted</li>
              <li>A ballet term which is a rise from a bent knee to the balls of the feet</li>
            </ul>
            <Box mb={-2}>Purpose:</Box>
            <ul>
              <li>To develop strength and control building a strong, secure and balanced foundation</li>
            </ul>
          </Paper>
          <Typography m={1}>
            If you have any questions about any of this information please{' '}
            <MuiLink component={RouterLink} to="/contact" color="textSecondary">
              contact us
            </MuiLink>
            .
          </Typography>
        </Grid>
        <Grid xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Image src={whims} width={300} alt="Young ballet dancer" />
        </Grid>
      </Grid>
    </Box>
  );
};

// Export component.
export default About;
