/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-08-31
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import ErrorAlert from '../../Common/ErrorAlert';
import PageSpinner from '../../Common/PageSpinner';
import { useGetPayrollQuery } from '../../../api/payrollSlice';
import { getTodaysDate, addDays, calculateHours } from '../../../assets/helpers/timecalc';

/**
 * PayrollSummary component.
 * @example
 * return (
 *   <PayrollSummary />
 * )
 * @returns {React.ReactElement} component.
 */
const PayrollSummary = () => {
  // Get default dates.
  const today = getTodaysDate();
  const past = addDays(today, -31);

  // Set state hooks.
  const [rows, setRows] = useState([]);
  const [totals, setTotals] = useState({});
  const [showUnprocessed, setShowUnprocessed] = useState(true);
  const [showProcessed, setShowProcessed] = useState(false);
  const [startDate, setStartDate] = useState(past);
  const [endDate, setEndDate] = useState(today);

  // Get payroll data.
  const { data: payrollData, error: getPayrollError, isLoading: getPayrollIsLoading } = useGetPayrollQuery();

  // Calculate the pay for each row.
  const calculatePay = (row) => {
    if (!row.rate) return 0;
    const hourlyPay = row.hours * row.hourlyRate;
    const minimumPay = Number(row.minimum || 0);
    const pay = hourlyPay < minimumPay ? minimumPay : hourlyPay;
    return pay;
  };

  // Calculate the pay for each row.
  const calculateUnprocessedPayPerUser = (payRows) => {
    return payRows.reduce((acc, row) => {
      const userKey = `${row.firstName} ${row.lastName}`;
      const pay = calculatePay(row);
      if (!acc[userKey]) {
        acc[userKey] = 0;
      }
      acc[userKey] += pay;
      return acc;
    }, {});
  };

  // Aggregate hours by user.
  const aggregateHoursByUser = (data) => {
    const aggregatedData = data.reduce((acc, item) => {
      const userKey = `${item.firstName} ${item.lastName}`;
      if (!acc[userKey]) {
        acc[userKey] = {
          ...item,
          totalHours: 0, // Initialize hours to 0
        };
      }
      acc[userKey].totalHours += parseFloat(item.hours);
      return acc;
    }, {});

    return Object.values(aggregatedData);
  };

  // Flatten the data and add total unprocessed pay per user.
  useEffect(() => {
    if (!payrollData) return;
    const flatData = payrollData?.map((item) => ({
      id: item.id,
      firstName: item.user.first_name,
      lastName: item.user.last_name,
      date: item.date,
      ic: 'N',
      sickPay: null,
      sickHours: null,
      hours: calculateHours(item.start_time, item.end_time),
      rate: item.pay_rate.rate,
      hourlyRate: item.pay_rate.flat_rate ? item.pay_rate.rate : item.pay_rate.rate * item.headcount,
      minimum: item.pay_rate.minimum,
      processed: item.processed,
    }));
    // Filter the data based on the showProcessed state
    const filteredData = flatData.filter((item) => {
      const isProcessedMatch = (showProcessed && item.processed) || (showUnprocessed && !item.processed);
      const isDateInRange =
        !startDate ||
        (new Date(item.date) >= new Date(startDate) && (!endDate || new Date(item.date) <= new Date(endDate)));
      return isProcessedMatch && isDateInRange;
    });
    // Calculate pay per user.
    const payPerUser = calculateUnprocessedPayPerUser(filteredData);
    // Calculate rate.
    const updatedRows = filteredData.map((row) => ({
      ...row,
      payCurrent: (payPerUser[`${row.firstName} ${row.lastName}`] || 0).toFixed(2),
      totalPay: (payPerUser[`${row.firstName} ${row.lastName}`] || 0).toFixed(2),
    }));
    const aggregatedData = aggregateHoursByUser(updatedRows);
    // Sort data by last name, first name, date, start time, and processed.
    aggregatedData.sort((a, b) => {
      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
      return 0;
    });
    setRows(aggregatedData);
    // Calculate column totals.
    const totalObject = aggregatedData.reduce(
      (acc, row) => {
        acc.sickPay += row.sickPay || 0;
        acc.sickHours += row.sickHours || 0;
        acc.payCurrent += parseFloat(row.payCurrent);
        acc.totalPay += parseFloat(row.totalPay);
        acc.totalHours += parseFloat(row.totalHours);
        acc.totalRate = acc.totalPay ? (acc.totalPay / acc.totalHours).toFixed(2) : 0;
        return acc;
      },
      { sickPay: 0, sickHours: 0, payCurrent: 0, totalPay: 0, totalHours: 0, totalRate: 0 }
    );
    setTotals(totalObject);
  }, [payrollData, showUnprocessed, showProcessed, startDate, endDate]);

  // Render component.
  return (
    <AuthMenuGroup>
      <Box>
        <Typography variant="h1" gutterBottom>
          Payroll Summary
        </Typography>
      </Box>
      {getPayrollError && <ErrorAlert error={getPayrollError} fallback="An unexpected error occurred." />}
      {getPayrollIsLoading && <PageSpinner />}
      <Stack direction="row" spacing={2}>
        <TextField
          id="start_data"
          label="Start Date"
          type="date"
          defaultValue={startDate}
          onChange={(event) => setStartDate(event.target.value)}
        />
        <TextField
          id="end_date"
          label="End Date"
          type="date"
          defaultValue={endDate}
          onChange={(event) => setEndDate(event.target.value)}
        />
        <FormControlLabel
          control={<Checkbox checked={showUnprocessed} onChange={() => setShowUnprocessed(!showUnprocessed)} />}
          label="Show Unprocessed"
        />
        <FormControlLabel
          control={<Checkbox checked={showProcessed} onChange={() => setShowProcessed(!showProcessed)} />}
          label="Show Processed"
        />
      </Stack>
      {rows.length === 0 ? (
        <ErrorAlert fallback="No rows found." severity="warning" />
      ) : (
        <TableContainer sx={{ mt: 2 }}>
          <Table size="small" aria-label="payroll summary">
            <TableHead sx={{ bgcolor: 'grey.200' }}>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell align="center">IC</TableCell>
                <TableCell align="right">Sick Pay</TableCell>
                <TableCell align="right">Sick Hours</TableCell>
                <TableCell align="right">Current Pay</TableCell>
                <TableCell align="right">Total Pay</TableCell>
                <TableCell align="right">Hours</TableCell>
                <TableCell align="right">Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const overallRate = row.totalPay ? row.totalPay / row.totalHours : 0;
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell align="center">{row.ic}</TableCell>
                    <TableCell align="right">{row.sickPay}</TableCell>
                    <TableCell align="right">{row.sickHours}</TableCell>
                    <TableCell align="right">{`$${row.payCurrent}`}</TableCell>
                    <TableCell align="right">{`$${row.totalPay}`}</TableCell>
                    <TableCell align="right">{row.totalHours}</TableCell>
                    <TableCell align="right">{`$${overallRate.toFixed(2)}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead sx={{ bgcolor: 'grey.200' }}>
              <TableRow>
                <TableCell>TOTAL</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
                <TableCell align="right">{`$${totals.sickPay.toFixed(2)}`}</TableCell>
                <TableCell align="right">{totals.sickHours}</TableCell>
                <TableCell align="right">{`$${totals.payCurrent.toFixed(2)}`}</TableCell>
                <TableCell align="right">{`$${totals.totalPay.toFixed(2)}`}</TableCell>
                <TableCell align="right">{totals.totalHours}</TableCell>
                <TableCell align="right">{`$${totals.totalRate}`}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </AuthMenuGroup>
  );
};

// Export component.
export default PayrollSummary;
