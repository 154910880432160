/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-16
 */

// Add third-party dependencies.
import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  useTheme,
  Box,
  AppBar,
  Toolbar,
  Link,
  IconButton,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fab,
} from '@mui/material';
import { LiveHelp as LiveHelpIcon } from '@mui/icons-material';

// Add local dependencies.
import ReleveStudios from '../Common/ReleveStudios';
import Icons from '../Common/Icons';
import NavMenu from './NavMenu';
import NavMenuList from './NavMenuList';
import MobileMenu from './MobileMenu';

/**
 * Header component.
 * @example
 * return (
 *   <Header />
 * )
 * @returns {React.ReactElement} component.
 */
const Header = () => {
  // Set theme hooks.
  const theme = useTheme();

  // Set state hooks.
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Set navigate hooks.
  const navigate = useNavigate();

  /**
   * Click event handler.
   * @param {string} url - New URL for navigation.
   */
  const handleListItemClick = (url) => () => {
    if (url.startsWith('http')) {
      window.location.href = url;
    } else {
      navigate(url);
      setMobileMenuOpen(false);
      setAnchorEl(null);
    }
  };

  // Menu items.
  const menuItems = (
    <Box role="navigation" sx={theme.mixins.toolbar}>
      <List>
        {NavMenuList().map((item) => (
          <Box component="li" key={item.label}>
            {item.divider ? <Divider /> : null}
            <ListItem role="menuitem" button onClick={handleListItemClick(item.link)}>
              <ListItemIcon>
                <Icons iconName={item.icon} />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          </Box>
        ))}
      </List>
    </Box>
  );

  // MobileMenu menu.
  const mobileMenuItems = <Box role="menu">{menuItems}</Box>;

  // Render component.
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        color="default"
        elevation={0}
        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar>
          <Link href="/" sx={{ width: 110, height: 75, margin: '10px' }}>
            <ReleveStudios />
          </Link>
          <Icons iconName="Phone" />
          <Link variant="body1" href="tel:+1-818-654-4140" sx={{ color: theme.palette.common.black }}>
            1-818-654-4140
          </Link>
          <Box sx={{ marginLeft: 'auto' }}>
            <Fab
              size="small"
              component={RouterLink}
              to="/chat-bot"
              // TODO: Unhide when chatbot is ready to go live. Remove display: none.
              sx={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)', display: 'none' }}
              aria-label="AI Assistant"
            >
              <LiveHelpIcon color="secondary" />
            </Fab>
            <IconButton
              color="inherit"
              aria-label="open menu"
              edge="start"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              sx={{ marginLeft: 2, [theme.breakpoints.up('sm')]: { display: 'none' } }}
            >
              <Icons iconName="Menu" />
            </IconButton>
          </Box>
          <NavMenu menuItems={menuItems} anchorEl={anchorEl} setAnchorEl={setAnchorEl} sx={{ display: 'none' }} />
        </Toolbar>
      </AppBar>
      <MobileMenu menuItems={mobileMenuItems} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
    </Box>
  );
};

// Export component.
export default Header;
