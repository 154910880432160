/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-23
 */

// Add local dependencies.
import MONTHS from '../constants/months';

/**
 * Formats a phone number string from '1234567890' to '(123) 456-7890'.
 * @param {string} phoneNumber - The phone number string to format.
 * @returns {string} The formatted phone number string.
 */
const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digits from the phone number.
  const cleanPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
  // Get the length of the phone number.
  const phoneNumberLength = cleanPhoneNumber.length;
  // If the phone number is less than 4 digits, return the phone number.
  if (phoneNumberLength < 4) return cleanPhoneNumber;
  // If the phone number is less than 7 digits, return the area code and prefix.
  if (phoneNumberLength < 7) return `(${cleanPhoneNumber.slice(0, 3)}) ${cleanPhoneNumber.slice(3)}`;
  // Otherwise, return the area code, prefix, and line number.
  return `(${cleanPhoneNumber.slice(0, 3)}) ${cleanPhoneNumber.slice(3, 6)}-${cleanPhoneNumber.slice(6, 10)}`;
};

/**
 * Formats a date string from 'YYYY-MM-DD' to 'Month Day, Year'.
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
const formatDate = (dateString) => {
  // Split the date string into an array of year, month, and day.
  const [year, month, day] = dateString.split('-');
  // Return the formatted date string.
  return `${MONTHS[parseInt(month, 10) - 1].label} ${parseInt(day, 10)}, ${year}`;
};

// Format a time stgring from 'HH:MM:SS' to 'HH:MM AM/PM'.
const formatTime = (timeString) => {
  if (!timeString) return '';
  const [hours, minutes] = timeString.split(':');
  const hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12;
  return `${hour12}:${minutes.padStart(2, '0')} ${ampm}`;
};

/**
 * Formats a time difference object to a string.
 * @param {object} timeDiff - The time difference object.
 * @returns {object} The formatted time difference object.
 */
const formatTimeDiff = ({ years, months, days, hours, minutes, seconds }) => {
  // Get interval value.
  const intervalValue = years || months || days || hours || minutes || seconds;
  // Get interval unit.
  let intervalUnit;
  if (years) {
    intervalUnit = 'year';
  } else if (months) {
    intervalUnit = 'month';
  } else if (days) {
    intervalUnit = 'day';
  } else if (hours) {
    intervalUnit = 'hour';
  } else if (minutes) {
    intervalUnit = 'minute';
  } else {
    intervalUnit = 'second';
  }
  // Get interval unit grammatical.
  const intervalUnitGrammatical = intervalValue > 1 ? `${intervalUnit}s` : intervalUnit;
  // Return the formatted time difference object.
  return { value: intervalValue, unit: intervalUnitGrammatical };
};

// Export the formatPhoneNumber function.
export { formatPhoneNumber, formatDate, formatTime, formatTimeDiff };
