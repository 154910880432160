/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-17
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Button, Menu } from '@mui/material';

/**
 * NavMenu component.
 * @example
 * return (
 *   <NavMenu />
 * )
 * @returns {React.ReactElement} component.
 */
const NavMenu = ({ menuItems, anchorEl, setAnchorEl }) => {
  // Render component.
  return (
    <>
      <Button
        area-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="secondary"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ marginLeft: 3, '@media (max-width: 600px)': { display: 'none' } }}
      >
        Menu
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setAnchorEl(null)}
        sx={{ '& .MuiPaper-root': { border: '1px solid #d3d4d5' } }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

// Set component property types.
NavMenu.propTypes = {
  menuItems: PropTypes.element.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.oneOf([null])]),
  setAnchorEl: PropTypes.func.isRequired,
};

// Set component default properties.
NavMenu.defaultProps = {
  anchorEl: null,
};

// Export component.
export default NavMenu;
