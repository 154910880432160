/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-22
 */

// Add third-party dependencies.
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import Image from 'mui-image';
import {
  useTheme,
  Alert,
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Dialog,
  IconButton,
} from '@mui/material';

// Add local dependencies.
import PageSpinner from '../Common/PageSpinner';
import Icons from '../Common/Icons';
import { useGetDynamicComponentsForPageQuery, useGetCampsQuery, useGetPdfQuery } from '../../api/publicSlice';
import DynamicComponentRenderer from '../Common/DynamicComponentRenderer';

/**
 * Formats a date string into 'MM/DD/YY' format.
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
const formatDate = (dateString) => {
  const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

/**
 * Formats a time string into 'HH:MM AM/PM' format.
 * @param {string} timeString - The time string to format, in 'HH:MM' format.
 * @returns {string} The formatted time string in 'HH:MM AM/PM' format.
 */
const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  const date = new Date();
  date.setHours(parseInt(hours, 10), parseInt(minutes, 10));
  // 'en-US' locale uses AM/PM; adjust if you need a different locale
  // Options to display hours and minutes without seconds, and to use 12-hour format with AM/PM
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
};

/**
 * Formats a date range string into 'MM/DD/YY - MM/DD/YY' format.
 * @param {string} startDate - The start date string to format, in 'YYYY-MM-DD' format.
 * @param {string} endDate - The end date string to format, in 'YYYY-MM-DD' format.
 * @returns {string} The formatted date range string in 'MM/DD/YY - MM/DD/YY' format.
 */
const formatDateRange = (startDate, endDate) => {
  const [startYear, startMonth, startDay] = startDate.split('-');
  const start = new Date(startYear, startMonth - 1, startDay);
  const [endYear, endMonth, endDay] = endDate.split('-');
  const end = new Date(endYear, endMonth - 1, endDay);
  const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
  return `${start.toLocaleDateString('en-US', options)} - ${end.toLocaleDateString('en-US', options)}`;
};

/**
 * Groups an array of camp objects by date range.
 * @param {Object[]} camps - The array of camp objects to group. Each object should have `start_date`, `end_date`, `title`, and `age_range` properties.
 * @returns {Object[]} An array of grouped camp objects. Each object has `id`, `date`, and `camps` properties. The `camps` property is an array of camp objects with `title` and `age_range` properties.
 */
const groupCampsByDate = (camps) => {
  const grouped = [];
  camps.forEach((camp) => {
    const rangeKey = formatDateRange(camp.start_date, camp.end_date);
    const group = grouped.find((g) => g.date === rangeKey);
    if (!group) {
      grouped.push({
        id: camp.id,
        date: rangeKey,
        camps: [camp],
      });
    } else {
      group.camps.push(camp);
    }
  });
  return grouped;
};

/**
 * Dates component.
 * @example
 * return (
 *   <Dates date="03/25/24 - 03/29/24" camps={["Fun Camp"]} />
 * )
 * @returns {React.ReactElement} component.
 */
const Dates = ({ date, camps }) => {
  // Set state hooks.
  const [open, setOpen] = useState(true);

  /**
   * Scroll to camp.
   */
  const scrollsToCamp = (camp) => {
    scroller.scrollTo(camp, {
      duration: 2000,
      delay: 0,
      smooth: 'easeInOutQuint',
      offset: -100,
    });
  };

  // Render component.
  return (
    <List component="div" sx={{ flexGrow: 1 }}>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Icons iconName="EventAvailable" />
        </ListItemIcon>
        <ListItemText primary={date} />
        {open ? <Icons iconName="ExpandLess" /> : <Icons iconName="ExpandMore" />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {camps.map((camp) => (
          <List key={camp.title} components="div" disablePadding>
            <ListItem button onClick={() => scrollsToCamp(camp.title)} sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Icons iconName="Sunny" />
              </ListItemIcon>
              <ListItemText primary={`${camp.age_range}: ${camp.title}`} />
            </ListItem>
          </List>
        ))}
      </Collapse>
    </List>
  );
};

/**
 * CampDescription component.
 * @example
 * return (
 *   <CampDescript camp={camps[0]} />
 * )
 * @returns {React.ReactElement} component.
 */
const CampDescription = ({ camp }) => {
  // Add theme hooks.
  const theme = useTheme();

  // Set state hooks.
  const [open, setOpen] = useState(false);

  // Render component.
  return (
    <>
      <Element name={camp.title} />
      <Dialog open={open}>
        <Box
          onClick={() => setOpen(false)}
          sx={{
            [theme.breakpoints.down('sm')]: {
              width: 250,
              height: 250,
            },
            [theme.breakpoints.up('md')]: {
              width: 500,
              height: 500,
            },
            overflow: 'auto',
            cursor: 'pointer',
            objectFit: 'cover',
          }}
        >
          <Image src={camp.camp_image?.image} width={500} alt={camp.title} />
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          >
            <Icons iconName="Close" />
          </IconButton>
        </Box>
      </Dialog>
      <Grid xs={12} sm={6} md={4} lg={3}>
        <Paper sx={{ minHeight: 164, padding: 2 }}>
          <Box onClick={() => setOpen(true)} sx={{ width: 160, height: 160, float: 'left', mr: 1, cursor: 'pointer' }}>
            <Image src={camp.camp_image?.image} width={160} alt={camp.title} />
          </Box>
          <Typography sx={{ fontWeight: 700 }}>{camp.title}</Typography>
          <Typography>{`${formatDate(camp.start_date)} - ${formatDate(camp.end_date)}`}</Typography>
          <Typography>{`${formatTime(camp.start_time)} - ${formatTime(camp.end_time)}`}</Typography>
          <Typography>{camp.age_range}</Typography>
          <Typography>
            {camp.description.split('\n').map((d) => (
              <span key={d}>
                {d}
                <br />
              </span>
            ))}
          </Typography>
        </Paper>
      </Grid>
    </>
  );
};

/**
 * Camps component.
 * @example
 * return (
 *   <Camps />
 * )
 * @returns {React.ReactElement} component.
 */
const Camps = () => {
  // Set router hook.
  const navigate = useNavigate();

  // Get data info from API.
  const {
    isLoading: isLoadingComponents,
    isError: isErrorComponents,
    error: errorComponents,
    data: dynamicComponentsData,
  } = useGetDynamicComponentsForPageQuery('camps');
  const { isLoading: isLoadingCamps, isError: isErrorCamps, error: errorCamps, data: campsData } = useGetCampsQuery();
  const { isLoading: isLoadingPdf, isError: isErrorPdf, error: errorPdf, data: pdfData } = useGetPdfQuery('camps');

  // If loading, display spinner.
  if (isLoadingComponents || isLoadingCamps || isLoadingPdf) {
    return <PageSpinner />;
  }

  // If error, display error message.
  if (isErrorComponents || isErrorCamps || isErrorPdf) {
    return (
      <Alert severity="error">
        There was a problem getting the camp info:{' '}
        {errorComponents?.error ||
          errorComponents?.message ||
          errorCamps?.error ||
          errorCamps?.message ||
          errorPdf?.error ||
          errorPdf?.message}
      </Alert>
    );
  }

  // Group camps by date.
  const dateGroups = groupCampsByDate(campsData);

  // Get title, subtitle, and details.
  const title = dynamicComponentsData?.find((component) => component.id === 13);
  const subtitle = dynamicComponentsData?.find((component) => component.id === 14);
  const details = dynamicComponentsData?.filter((component) => component.id >= 15);

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1" gutterBottom>
        Camps
      </Typography>
      {title && <DynamicComponentRenderer component={title.component} props={title.props} />}
      {subtitle && <DynamicComponentRenderer component={subtitle.component} props={subtitle.props} />}
      <Grid container spacing={3}>
        <Grid xs={12} sm={6}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<Icons iconName="Mail" />}
            centerRipple
            fullWidth
            onClick={() => navigate('/camps-contact')}
          >
            I&apos;m interested, please contact me.
          </Button>
        </Grid>
        <Grid xs={12} sm={6}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<Icons iconName="PictureAsPdf" />}
            centerRipple
            fullWidth
            href={pdfData[0]?.pdf}
          >
            Summer Camp PDF
          </Button>
        </Grid>
        <Grid xs={12} sm={6}>
          <Paper component="nav" p={2}>
            {dateGroups.map((group) => (
              <Dates key={group.id} date={group.date} camps={group.camps} />
            ))}
          </Paper>
        </Grid>
        <Grid xs={12} sm={6}>
          <Paper sx={{ p: 4 }}>
            {details.map((item) => (
              <DynamicComponentRenderer key={item.id} component={item.component} props={item.props} />
            ))}
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Button
            size="large"
            onClick={() => navigate('/register')}
            variant="contained"
            color="secondary"
            startIcon={<Icons iconName="PersonAdd" />}
            centerRipple
            fullWidth
          >
            Click here to register
          </Button>
        </Grid>
        {campsData.map((camp) => (
          <CampDescription key={camp.id} camp={camp} />
        ))}
      </Grid>
    </Box>
  );
};

const campShape = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
  start_time: PropTypes.string.isRequired,
  end_time: PropTypes.string.isRequired,
  age_range: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  camp_image: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

// Set component property types.
Dates.propTypes = {
  date: PropTypes.string.isRequired,
  camps: PropTypes.arrayOf(PropTypes.shape(campShape).isRequired).isRequired,
};

// Set component property types.
CampDescription.propTypes = {
  camp: PropTypes.shape(campShape).isRequired,
};

// Export component.
export default Camps;
