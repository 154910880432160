/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-08-17
 */

// Add third-party dependencies.
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar, GridRowModes, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  EditOutlined as EditOutlinedIcon,
} from '@mui/icons-material';
import { Box, Typography, Stack, Button, Tooltip, TextField } from '@mui/material';

// Add local dependencies.
import { parseDateAsLocal, parseDateTimeAsLocal, calculateHours } from '../../../assets/helpers/timecalc';
import { formatTime } from '../../../assets/helpers/format';
import AuthMenuGroup from '../AuthMenuGroup';
import PageSpinner from '../../Common/PageSpinner';
import ErrorAlert from '../../Common/ErrorAlert';
import Question from '../../Common/Question';
import { useGetPayrollQuery, useProcessActivitiesMutation, useGetAllPayRatesQuery } from '../../../api/payrollSlice';
import { useUpdateActivityMutation, useDeleteActivityMutation } from '../../../api/employSlice';
import AuthContext from '../../../context/AuthContext';

/**
 * PayrollReport component.
 * @example
 * return (
 *   <PayrollReport />
 * )
 * @returns {React.ReactElement} component.
 */
const PayrollReport = ({ title }) => {
  // Set state hooks.
  const [processActivitiesError, setProcessActivitiesError] = useState(null);
  const [processActivitiesSuccess, setProcessActivitiesSuccess] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowToDelete, setRowToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [individualPayRates, setIndividualPayRates] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  // Set context hooks.
  const { user } = useContext(AuthContext);

  // RTK Query hooks for updating and deleting activities
  const [updateActivity, { isLoading: isUpdating }] = useUpdateActivityMutation();
  const [deleteActivity, { isLoading: isDeleting }] = useDeleteActivityMutation();
  const { isLoading: getRatesIsLoading, isError: getRatesError, data: payRates = [] } = useGetAllPayRatesQuery();

  // Get payroll data.
  const { data: payrollData, error: getPayrollError, isLoading: getPayrollIsLoading } = useGetPayrollQuery();
  const [processActivities, { isLoading: processActivitiesIsLoading }] = useProcessActivitiesMutation();

  // Calculate the pay for each row.
  const calculatePay = (row) => {
    if (!row.rate) return 0;
    const hourlyPay = row.hours * row.hourlyRate;
    const minimumPay = Number(row.minimum || 0);
    const pay = hourlyPay < minimumPay ? minimumPay : hourlyPay;
    return pay;
  };

  // Calculate the pay for each row.
  const calculateUnprocessedPayPerUser = (payRows) => {
    const unprocessedRows = payRows.filter((row) => !row.processed);
    return unprocessedRows.reduce((acc, row) => {
      const userKey = `${row.firstName} ${row.lastName}`;
      const pay = calculatePay(row);
      if (!acc[userKey]) {
        acc[userKey] = 0;
      }
      acc[userKey] += pay;
      return acc;
    }, {});
  };

  // Flatten the data and add total unprocessed pay per user.
  useEffect(() => {
    if (!payrollData) return;
    const flatData = payrollData?.map((item) => ({
      id: item.id,
      firstName: item.user.first_name,
      lastName: item.user.last_name,
      activity: item.activity,
      date: item.date,
      start_time: item.start_time,
      end_time: item.end_time,
      hours: calculateHours(item.start_time, item.end_time),
      pay_rate: item.pay_rate.id,
      rateName: item.pay_rate.rate_name,
      rate: item.pay_rate.rate,
      hourlyRate: item.pay_rate.flat_rate ? item.pay_rate.rate : item.pay_rate.rate * item.headcount,
      flatRate: item.pay_rate.flat_rate,
      minimum: item.pay_rate.minimum,
      headcount: item.headcount,
      processed: item.processed,
      created: item.created_at,
      updated: item.updated_at,
    }));
    const payPerUser = calculateUnprocessedPayPerUser(flatData);
    const updatedRows = flatData.map((row) => ({
      ...row,
      totalUnprocessedPay: payPerUser[`${row.firstName} ${row.lastName}`] || 0,
    }));
    // Sort data by last name, first name, date, start time, and processed.
    updatedRows.sort((a, b) => {
      if (a.processed < b.processed) return -1;
      if (a.processed > b.processed) return 1;
      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
      if (a.date > b.date) return -1; // Descending order
      if (a.date < b.date) return 1; // Descending order
      if (a.start_time > b.start_time) return -1; // Descending order
      if (a.start_time < b.start_time) return 1; // Descending order
      return 0;
    });
    setRows(updatedRows);
  }, [payrollData]);

  // Handle row edit stop event.
  const handleRowEditStop = (params, event) => {
    setIsEditMode(false);
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true; // eslint-disable-line no-param-reassign
    }
  };

  // Handle edit click event.
  const handleEditClick = (id) => () => {
    setIsEditMode(true);
    const selectedRow = payrollData.find((row) => row.id === id);
    const userId = selectedRow?.user?.id;
    const userRates = payRates.filter((rate) => rate.user === userId);
    setIndividualPayRates(userRates);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  // Handle save click event.
  const handleSaveClick = (id) => () => {
    setIsEditMode(false);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // Handle delete click event.
  const handleDeleteClick = (id) => () => {
    setRowToDelete(id);
    setDeleteDialogOpen(true);
  };

  // Handle confirm delete event.
  const handleConfirmDelete = async () => {
    if (rowToDelete) {
      try {
        await deleteActivity(rowToDelete).unwrap();
        setRows((prevRows) => prevRows.filter((row) => row.id !== rowToDelete));
      } catch (error) {
        console.error('Failed to delete row:', error);
        setErrorAlert(error);
      } finally {
        setDeleteDialogOpen(false);
        setRowToDelete(null);
      }
    }
  };

  // Handle cancel delete event.
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  // Handle cancel click event.
  const handleCancelClick = (id) => () => {
    setIsEditMode(false);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  // Process row update.
  const processRowUpdate = async (updatedRow) => {
    try {
      // Validation logic.
      if (!updatedRow.activity) throw new Error('Activity cannot be empty.');
      if (updatedRow.start_time >= updatedRow.end_time) throw new Error('Start time must be earlier than end time.');
      if (updatedRow.headcount < 0) throw new Error('Headcount cannot be a negative.');
      // Ensure the date is in the correct format (YYYY-MM-DD).
      const formattedRow = {
        ...updatedRow,
        user: user.id,
        date: updatedRow.date.toISOString().split('T')[0], // Extract the date portion.
      };
      await updateActivity({ id: formattedRow.id, ...formattedRow }).unwrap();
      // Update the local state with the new data.
      setRows((prevRows) => prevRows.map((row) => (row.id === updatedRow.id ? formattedRow : row)));
      return formattedRow;
    } catch (error) {
      console.error('Failed to update row:', error);
      setErrorAlert(error);
      throw error; // Revert the changes in the grid if the update fails.
    }
  };

  // Handle row modes model change.
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Handle processing activities.
   * @returns {Promise<void>} a promise.
   */
  const handleProcessActivities = async () => {
    try {
      const response = await processActivities({ ids: rowSelectionModel });
      if (response.error) throw new Error('There was a problem setting rows as processed.');
      if (response.data) setProcessActivitiesSuccess(response.data.message);
    } catch (error) {
      // Handle error
      console.error('Failed to process activities:', error);
      setProcessActivitiesError(error);
    }
  };

  // Create single select value options.
  const singleSelectValueOptions = payRates.map((rate) => ({
    value: rate.id,
    label: `${rate.rate_name}: $${rate.rate}/${rate.flat_rate ? 'hour' : 'head'}`,
  }));

  // Create single select value options.
  const singleSelectValueOptionsEdit = individualPayRates.map((rate) => ({
    value: rate.id,
    label: `${rate.rate_name}: $${rate.rate}/${rate.flat_rate ? 'hour' : 'head'}`,
  }));

  // Set column definitions.
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (row.processed) {
          // If the row is processed, disable the Edit and Delete buttons
          return [
            <Tooltip title="Cannot edit a processed row" key="edit-disabled">
              <GridActionsCellItem icon={<EditOutlinedIcon sx={{ color: 'gray' }} />} label="Edit" />
            </Tooltip>,
            <Tooltip title="Cannot delete a processed row" key="delete-disabled">
              <GridActionsCellItem icon={<DeleteOutlinedIcon sx={{ color: 'gray' }} />} label="Delete" />
            </Tooltip>,
          ];
        }

        if (isInEditMode) {
          return [
            <Tooltip title="Save changes" key="save">
              <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />
            </Tooltip>,
            <Tooltip title="Cancel changes" key="cancel">
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
              />
            </Tooltip>,
          ];
        }

        return [
          <Tooltip title="Edit row" key="edit">
            <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(id)} />
          </Tooltip>,
          <Tooltip title="Delete row" key="delete">
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} />
          </Tooltip>,
        ];
      },
    },
    { field: 'id', headerName: 'ID', type: 'number' },
    { field: 'firstName', headerName: 'First Name' },
    { field: 'lastName', headerName: 'Last Name' },
    {
      field: 'totalUnprocessedPay',
      headerName: 'Total Pay',
      type: 'number',
      valueFormatter: (value) => `$${value.toFixed(2)}`,
    },
    { field: 'activity', headerName: 'Activity', editable: true },
    {
      field: 'date',
      headerName: 'Date',
      editable: true,
      type: 'date',
      valueGetter: (date) => (typeof date === 'string' ? parseDateAsLocal(date) : date),
    },
    {
      field: 'start_time',
      headerName: 'Start Time',
      editable: true,
      valueFormatter: (value) => formatTime(value),
      renderEditCell: (params) => (
        <TextField
          type="time"
          value={params.value || ''}
          onChange={(event) => {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
          }}
          variant="standard"
          InputProps={{ style: { padding: '5px' } }}
        />
      ),
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      editable: true,
      valueFormatter: (value) => formatTime(value),
      renderEditCell: (params) => (
        <TextField
          type="time"
          value={params.value || ''}
          onChange={(event) => {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
          }}
          variant="standard"
          InputProps={{ style: { padding: '5px' } }}
        />
      ),
    },
    { field: 'hours', headerName: 'Hours', type: 'number' },
    {
      field: 'pay_rate',
      headerName: 'Pay Rate',
      editable: true,
      type: 'singleSelect',
      width: 200,
      valueOptions: isEditMode ? singleSelectValueOptionsEdit : singleSelectValueOptions,
    },
    // ...(isEditMode
    //   ? [
    //       {
    //         field: 'pay_rate',
    //         headerName: 'Pay Rate',
    //         editable: true,
    //         type: 'singleSelect',
    //         width: 200,
    //         valueOptions: singleSelectValueOptions,
    //       },
    //     ]
    //   : [
    //       { field: 'rateName', headerName: 'Rate Name' },
    //       { field: 'flatRate', headerName: 'Flat Rate', type: 'boolean' },
    //       { field: 'rate', headerName: 'Rate', type: 'number', valueFormatter: (params) => `$${params}` },
    //     ]),
    { field: 'minimum', headerName: 'Minimum', type: 'number', valueFormatter: (params) => `$${params}` },
    {
      field: 'pay',
      headerName: 'Pay',
      type: 'number',
      valueGetter: (params, row) => calculatePay(row),
      valueFormatter: (params) => `$${params.toFixed(2)}`,
    },
    { field: 'headcount', headerName: 'Headcount', type: 'number', editable: true },
    { field: 'processed', headerName: 'Processed', type: 'boolean' },
    {
      field: 'created',
      headerName: 'Created',
      type: 'dateTime',
      valueGetter: (date) => (typeof date === 'string' ? parseDateTimeAsLocal(date) : date),
    },
    {
      field: 'updated',
      headerName: 'Updated',
      type: 'dateTime',
      valueGetter: (date) => (typeof date === 'string' ? parseDateTimeAsLocal(date) : date),
    },
  ];

  // Render component.
  return (
    <AuthMenuGroup>
      <Box>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box mt={2}>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" disabled={rowSelectionModel.length === 0} onClick={handleProcessActivities}>
            Set checked rows as processed
          </Button>
          {errorAlert && (
            <ErrorAlert error={errorAlert} fallback="An unexpected error occurred. Please try again later." />
          )}
          {getPayrollError && <ErrorAlert error={getPayrollError} backup="An unexpected error occurred." />}
          {processActivitiesError && (
            <ErrorAlert error={processActivitiesError} backup="There was a problem setting rows to processed." />
          )}
          {processActivitiesSuccess && (
            <ErrorAlert
              error={processActivitiesSuccess}
              backup="Activities set as processed successfully."
              severity="success"
            />
          )}
          {getRatesError && <ErrorAlert error={getRatesError} backup="There was an error loading pay rates." />}
          {(getPayrollIsLoading || processActivitiesIsLoading || isUpdating || isDeleting || getRatesIsLoading) && (
            <PageSpinner />
          )}
        </Stack>
        <DataGrid
          sx={{ mt: 2 }}
          rows={rows}
          columns={columns}
          autoHeight
          editMode="row"
          rowModesModel={rowModesModel}
          pageSizeOptions={[10, 25, 50, 75, 100]}
          disableMultipleColumnsSorting={false}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          onRowEditStop={handleRowEditStop}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: { paginationModel: { pageSize: 50 } },
            sorting: {
              sortModel: [{ field: 'lastName', sort: 'asc' }],
            },
            filter: {
              filterModel: {
                items: [{ field: 'processed', operator: 'is', value: 'false' }],
              },
            },
            density: 'compact',
          }}
          slots={{ toolbar: GridToolbar }}
        />
        <Question
          title="Are you sure?"
          option1Handler={handleConfirmDelete}
          option2Handler={handleCancelDelete}
          question={`Are you sure you want to delete activity (ID ${rowToDelete})?`}
          open={deleteDialogOpen}
          onClose={handleCancelDelete}
        />
      </Box>
    </AuthMenuGroup>
  );
};

// Set component property types.
PayrollReport.propTypes = {
  title: PropTypes.string,
};

// Set component default properties.
PayrollReport.defaultProps = {
  title: 'Payroll Report',
};

// Export component.
export default PayrollReport;
