/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-09
 */

// Add third-party dependencies.
import { useEffect } from 'react';
import { Box } from '@mui/material';

/**
 * ChatWidget component.
 * @example
 * return (
 *   <ChatWidget />
 * )
 * @returns {React.ReactElement} component.
 */
const ChatWidget = () => {
  // Chat widget script injection.
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widgets.leadconnectorhq.com/loader.js';
    // script.async = true;
    script['data-resources-url'] = 'https://widgets.leadconnectorhq.com/chat-widget/loader.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Formats the chat widget by changing the background color and text color.
   */
  const formatWidget = () => {
    const bgcolor = '#4F0B7B';
    let widget = document.querySelector('chat-widget.hydrated');
    if (widget)
      widget = document.querySelector('chat-widget.hydrated').shadowRoot.querySelector('#lc_text-widget--btn');
    if (widget) {
      widget.style.backgroundColor = bgcolor;
      widget = document
        .querySelector('chat-widget.hydrated')
        .shadowRoot.querySelector('div.lc_text-widget_heading--root');
      if (widget) widget.style.backgroundColor = bgcolor;
      widget = document.querySelector('chat-widget.hydrated').shadowRoot.querySelector('#lc_text-widget--send-btn');
      if (widget) widget.style.backgroundColor = bgcolor;
      widget = document
        .querySelector('chat-widget.hydrated')
        .shadowRoot.querySelector('#msgsndr_message-form > div:nth-child(1) > label');
      if (widget) widget.style.color = bgcolor;
      widget = document
        .querySelector('chat-widget.hydrated')
        .shadowRoot.querySelector('#msgsndr_message-form > div:nth-child(2) > label');
      if (widget) widget.style.color = bgcolor;
    }
  };

  // Format the chat widget.
  useEffect(() => {
    formatWidget();
    const timer = setInterval(formatWidget, 50);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Render component.
  return (
    <Box
      component="chat-widget"
      location-id="9MwYC27wHfGoNjrXqRvy"
      heading="Have a question about our dance programs?"
      sub-heading="Enter your question below and someone from our studio will contact you shortly! 😊"
      prompt-msg="Hi there, have a question about our dance programs? Text us here."
      support-contact="(818) 654-4140"
      success-msg="Someone from our studio will contact you shortly."
      prompt-avatar="https://firebasestorage.googleapis.com/v0/b/highlevel-backend.appspot.com/o/locationPhotos%2F9MwYC27wHfGoNjrXqRvy%2Fchat-widget-person?alt=media&token=56f7365b-0fa7-4101-9264-e08cfc848130"
    />
  );
};

// Export component.
export default ChatWidget;
