/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-12
 */

// Add third-party dependencies.
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, List, ListItem, ListItemText, Box, CircularProgress, Alert } from '@mui/material';

/**
 * MessageList component.
 * @example
 * return (
 *   <MessageList />
 * )
 * @returns {React.ReactElement} component.
 */
const MessageList = ({ conversation, isLoading, error }) => {
  // Set the reference to the bottom of the list of messages.
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the list of messages.
  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  // Scroll to the bottom of the list of messages when the conversation changes.
  useEffect(() => scrollToBottom(), [conversation]);

  // Handle error.
  if (error) console.error('Error:', error);

  // Render component.
  return (
    <Paper variant="outlined" sx={{ height: '100%', m: 2 }}>
      <List sx={{ maxHeight: 350, overflow: 'auto' }}>
        {conversation.map((message) => (
          <ListItem key={message.id}>
            <ListItemText
              primary={message.role === 'bot' ? 'Clara' : 'You'}
              // eslint-disable-next-line react/no-danger
              secondary={<span dangerouslySetInnerHTML={{ __html: message.text }} />}
            />
          </ListItem>
        ))}
        {isLoading && (
          <ListItem>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <CircularProgress />
            </Box>
          </ListItem>
        )}
        {error && (
          <ListItem>
            <Alert severity="error">
              I appologize. I am having problems today. Please feel free to contact us using the information on the
              contact page.
            </Alert>
          </ListItem>
        )}
        <Box ref={messagesEndRef} />
      </List>
    </Paper>
  );
};

// Set component property types.
MessageList.propTypes = {
  conversation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      role: PropTypes.oneOf(['bot', 'user']).isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
};

// Set default properties.
MessageList.defaultProps = {
  error: null,
};

// Export component.
export default MessageList;
