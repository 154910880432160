/* eslint-disable react/jsx-props-no-spreading */
/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-08-07
 */

// Add third-party dependencies.
import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller, useWatch } from 'react-hook-form';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from '@mui/material';

// Add local dependencies.
import { getTodaysDate, roundDownTimeToHalfHour, addHours } from '../../../assets/helpers/timecalc';
import AuthMenuGroup from '../AuthMenuGroup';
import { useGetRatesQuery, useGetActivitiesQuery, useAddActivityMutation } from '../../../api/employSlice';
import PageSpinner from '../../Common/PageSpinner';
import ErrorAlert from '../../Common/ErrorAlert';
import FormText from '../../Common/FormText';
import AuthContext from '../../../context/AuthContext';
import TimesheetGrid from './TimesheetGrid';

/**
 * Timesheet component.
 * @example
 * return (
 *   <Timesheet />
 * )
 * @returns {React.ReactElement} component.
 */
const Timesheet = ({ title }) => {
  // Set state hooks.
  const [defaultTimesheetDate, setDefaultTimesheetDate] = useState('');
  const [defaultStartTime, setDefaultStartTime] = useState('');
  const [defaultEndTime, setDefaultEndTime] = useState('');
  const [isHeadcountDisabled, setIsHeadcountDisabled] = useState(false);
  const [defaultPayRate, setDefaultPayRate] = useState(null);

  // Set context hooks.
  const { user } = useContext(AuthContext);

  // Set form hooks.
  const { control, handleSubmit, setValue, getValues } = useForm();

  // Set effect hook.
  useEffect(() => {
    const today = getTodaysDate();
    const timesheetDate = localStorage.getItem('timesheetDate');
    const startTime = localStorage.getItem('startTime');
    const endTime = localStorage.getItem('endTime');
    const roundedStartTime = roundDownTimeToHalfHour(new Date());
    const roundedEndTime = addHours(roundedStartTime, 1);

    setDefaultTimesheetDate(timesheetDate || today);
    setDefaultStartTime(startTime || roundedStartTime);
    setDefaultEndTime(endTime || roundedEndTime);

    setValue('date', timesheetDate || today); // Set the default value for the date field
    setValue('start_time', startTime || roundedStartTime); // Set the default value for the start time field
    setValue('end_time', endTime || roundedEndTime); // Set the default value for the end time field
    setValue('headcount', isHeadcountDisabled ? 0 : 1); // Set the default value for the headcount field
  }, [setValue]);

  // Set query hooks.
  const {
    isLoading: getRatesIsLoading,
    isError: getRatesError,
    data: payRates = [],
    isSuccess: getRatesSucess,
  } = useGetRatesQuery();
  const {
    isLoading: getActivitiesIsLoading,
    isError: getActivitiesError,
    data: activities = [],
    refetch: refetchActivities,
  } = useGetActivitiesQuery();

  // Set mutation hooks.
  const [addTimesheet, { isLoading: isAdding }] = useAddActivityMutation();

  // Set watch hooks.
  const startTimeValue = useWatch({ control, name: 'start_time' });
  const endTimeValue = useWatch({ control, name: 'end_time' });
  const payRateValue = useWatch({ control, name: 'pay_rate' });

  // Set effect hook to handle pay_rate changes.
  useEffect(() => {
    if (payRateValue) {
      const selectedPayRate = payRates.find((rate) => rate.id === payRateValue);
      if (selectedPayRate && selectedPayRate.flat_rate) {
        setValue('headcount', 0);
        setIsHeadcountDisabled(true);
      } else {
        setValue('headcount', 1);
        setIsHeadcountDisabled(false);
      }
      setDefaultPayRate(payRateValue);
    } else if (payRates.length > 0) {
      const defaultRate = defaultPayRate || payRates[0].id;
      setValue('pay_rate', defaultRate);
      setDefaultPayRate(defaultRate);
    }
  }, [payRateValue, payRates, setValue]);

  // Set effect hook.
  useEffect(() => {
    if (startTimeValue && endTimeValue) {
      const start = new Date(`1970-01-01T${startTimeValue}`);
      const end = new Date(`1970-01-01T${endTimeValue}`);

      if (end <= start) {
        setValue('end_time', addHours(startTimeValue, 1));
      }
    }
  }, [startTimeValue, endTimeValue, setValue]);

  // Function to update the form with row data, except for the date
  const handleReplay = (rowData) => {
    setValue('activity', rowData.activity);
    setValue('start_time', rowData.start_time);
    setValue('end_time', rowData.end_time);
    setValue('headcount', rowData.headcount);
    setValue('pay_rate', rowData.pay_rate);
  };
  // Check for error state.
  if (getRatesError) return <ErrorAlert error={getRatesError} fallback="Error loading pay rates." />;
  if (getRatesSucess && payRates.length === 0)
    return <ErrorAlert error="No pay rates found. Please notify management." severity="warning" />;

  /**
   * Handle form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    localStorage.setItem('timesheetDate', data.date); // Save the date to local storage
    localStorage.setItem('startTime', data.start_time); // Save the start time to local storage
    localStorage.setItem('endTime', data.end_time); // Save the end time to local storage

    const formData = {
      ...data,
      user: user.id, // Assuming user object has an `id` property
      headcount: data.headcount === '' ? 0 : data.headcount, // Convert empty headcount to zero
      pay_rate: data.pay_rate === '' ? null : data.pay_rate, // Convert empty pay_rate to null
    };
    await addTimesheet(formData);
    refetchActivities();
  };

  // Get distinct activities ignoring case but keeping the case of at least one occurrence.
  const activityMap = new Map();
  activities.forEach((item) => {
    const lowerCaseActivity = item.activity.toLowerCase();
    if (!activityMap.has(lowerCaseActivity)) {
      activityMap.set(lowerCaseActivity, item.activity);
    }
  });
  const activitySuggestions = [...activityMap.values()];

  // Render component.
  return (
    <AuthMenuGroup>
      {isAdding && <PageSpinner />}
      <Box>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ width: '100%' }}>
        <Grid container spacing={1}>
          <Grid xs={6} md={3.5}>
            <Controller
              name="activity"
              control={control}
              defaultValue={null}
              rules={{ required: 'Activity is required.' }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={activitySuggestions}
                  getOptionLabel={(option) => option || ''}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Activity"
                      margin="dense"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                    />
                  )}
                  onChange={(event, value) => field.onChange(value)}
                  onInputChange={(event, value) => {
                    if (value) {
                      field.onChange(value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  value={field.value || null}
                />
              )}
            />
          </Grid>
          <Grid xs={6} md={1.5}>
            <FormText
              control={control}
              name="date"
              label="Date"
              type="date"
              defaultValue={defaultTimesheetDate}
              rules={{ required: 'Date is required.' }}
              margin="dense"
              shrinkLabel
            />
          </Grid>
          <Grid xs={6} md={1.5}>
            <FormText
              control={control}
              name="start_time"
              label="Start Time"
              type="time"
              defaultValue={defaultStartTime}
              rules={{ required: 'Start time is required.' }}
              margin="dense"
              shrinkLabel
            />
          </Grid>
          <Grid xs={6} md={1.5}>
            <FormText
              control={control}
              name="end_time"
              label="End Time"
              type="time"
              defaultValue={defaultEndTime}
              rules={{
                required: 'End time is required.',
                validate: (value) => {
                  const startValue = startTimeValue || getValues('start_time');
                  const start = new Date(`1970-01-01T${startValue}`);
                  const end = new Date(`1970-01-01T${value}`);
                  return end > start || 'End time must be later than start time.';
                },
              }}
              margin="dense"
              shrinkLabel
            />
          </Grid>
          <Grid xs={6} md={1.5}>
            {getRatesIsLoading ? (
              <PageSpinner backdrop={false} />
            ) : (
              <FormControl fullWidth margin="dense">
                <Controller
                  name="pay_rate"
                  control={control}
                  defaultValue={defaultPayRate || payRates[0].id}
                  render={({ field, fieldState }) => (
                    <Select {...field} labelId="rate-label" error={!!fieldState.error} label="Pay Rate">
                      {payRates.map((rate) => (
                        <MenuItem key={rate.id} value={rate.id}>
                          {`${rate.rate_name}: $${rate.rate}/${rate.flat_rate ? 'hour' : 'head'}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <InputLabel id="rate-label" shrink>
                  Pay Rate
                </InputLabel>
              </FormControl>
            )}
          </Grid>
          <Grid xs={6} md={1.5}>
            <FormControl fullWidth margin="dense">
              <Controller
                name="headcount"
                control={control}
                defaultValue={isHeadcountDisabled ? 0 : 1}
                rules={{
                  required: 'Headcount is required.',
                  validate: (value) => isHeadcountDisabled || value > 0 || 'Headcount is required.',
                  min: { value: 0, message: 'Headcount cannot be less than 0.' },
                  max: { value: 99, message: 'Headcount cannot be more than 99.' },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Headcount"
                    type="number"
                    inputProps={{ min: isHeadcountDisabled ? 0 : 1, max: 99 }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    disabled={isHeadcountDisabled}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid xs={12} md={1}>
            <Button type="submit" fullWidth variant="contained" sx={{ marginTop: 2 }}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        {getActivitiesIsLoading && getRatesIsLoading && <PageSpinner backdrop={false} />}
        {getActivitiesError && <ErrorAlert error={getActivitiesError} fallback="Error loading timesheet activities" />}
        {activities && payRates.length > 0 && (
          <Box>
            <TimesheetGrid activities={activities} payRates={payRates} onReplay={handleReplay} />
          </Box>
        )}
      </Box>
    </AuthMenuGroup>
  );
};

// Set component property types.
Timesheet.propTypes = {
  title: PropTypes.string,
};

// Set component default properties.
Timesheet.defaultProps = {
  title: 'Timesheet',
};

// Export component.
export default Timesheet;
