/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-23
 */

// Add third-party dependencies.
import { useContext } from 'react';
import { Typography, CircularProgress, Grid, Card, CardContent } from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import AuthContext from '../../../context/AuthContext';
import ErrorAlert from '../../Common/ErrorAlert';
import { timeSince } from '../../../assets/helpers/timecalc';
import { formatTimeDiff } from '../../../assets/helpers/format';

/**
 * Welcome component.
 * @example
 * return (
 *   <Welcome />
 * )
 * @returns {React.ReactElement} component.
 */
const Welcome = () => {
  // Set context hook.
  const { user, loading, authError } = useContext(AuthContext);

  // Check for loading and error states.
  if (loading) return <CircularProgress />;
  if (authError) return <ErrorAlert error={authError} fallback="Error loading profile." />;

  const updatedAt = timeSince(new Date(user?.updated_at));
  const { value, unit } = formatTimeDiff(updatedAt);
  const lastProfileUpdate = `${value} ${unit} ago`;

  const isActive = `${user?.is_active ? 'Is Active' : 'Not Acitve'}`;
  const isStaff = `${user?.is_staff ? ', Is Staff' : ''}`;
  const isSuperuser = `${user?.is_superuser ? ', Is Superuser' : ''}`;
  const accountStatus = `${isActive}${isStaff}${isSuperuser}`;

  // Render component.
  return (
    <AuthMenuGroup>
      <Typography variant="h1">Welcome</Typography>
      {authError && <ErrorAlert error={authError} fallback="Error loading profile." />}
      {user && (
        <>
          <Typography component="h2" variant="h4" gutterBottom sx={{ mt: 3 }}>
            Hi, {user?.display_name || user?.first_name || user?.username}!
          </Typography>
          <Typography variant="body1" gutterBottom>
            You have successfully logged into the Relevé private site.
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography component="h3" variant="h6">
                    Last Profile Update
                  </Typography>
                  <Typography variant="body2">{lastProfileUpdate}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography component="h3" variant="h6">
                    Account Status
                  </Typography>
                  <Typography variant="body2">{accountStatus}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </AuthMenuGroup>
  );
};

// Export component.
export default Welcome;
