/**
 * Little Shilling, Inc.
 * Shon Little
 * February 2, 2024
 */

// Add local dependencies.
import themeReleve from './releve';

/**
 * Create Material-UI global light theme to override the default Releve theme for mobile devices.
 * @param {Object} - Theme options.
 * @returns {Object} Theme.
 */
const themeMobile = {
  ...themeReleve,
  typography: {
    ...themeReleve.typography,
    h1: {
      ...themeReleve.typography.h1,
      fontSize: '2.5rem',
    },
  },
};

// Export theme
export default themeMobile;
