/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-23
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

/**
 * Extract error message from various error structures.
 * @param {Object|String} error - The error object or string.
 * @returns {String} - Extracted error message.
 */
const getErrorMessage = (error) => {
  if (!error) return null;

  // Handle HTTP error response.
  if (error.status) {
    if (error?.data?.code === 'token_not_valid') return 'Session expired. Please log in.';
    switch (error.status) {
      case 400:
        return error.data.detail || 'Bad request. Please try again.';
      case 401:
        return error.data.detail || 'Unauthorized. Please log in.';
      case 403:
        return 'Forbidden. You do not have permission to access this resource.';
      case 404:
        return 'Not found. The requested resource does not exist.';
      case 500:
        return 'Internal server error. Please try again later.';
      default:
        return error.message || error.statusText || error.status || error.data.messages[0].message;
    }
  }

  // Handle standard Error object.
  if (error instanceof Error) {
    return error.message;
  }

  // Handle error object with nested messages.
  if (error.message) {
    return error.message;
  }

  // Handle HTTP error response.
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  }

  // Handle string errors.
  if (typeof error === 'string') {
    return error;
  }

  // Handle unknown error structures.
  return JSON.stringify(error);
};

/**
 * ErrorAlert component.
 * @example
 * return (
 *   <ErrorAlert error={new Error('This is an error message')} />
 * )
 * @param {Object|String} error - The error object or string.
 * @param {String} fallback - The fallback error message.
 * @param {String} severity - The severity level of the alert.
 * @returns {React.ReactElement} component.
 */
const ErrorAlert = ({ error, fallback, severity }) => {
  // Log error to console.
  if (severity === 'error') console.error('ErrorAlert:', error);

  // Set error message.
  const message = getErrorMessage(error) || fallback;

  // Render component.
  return <Alert severity={severity}>{message}</Alert>;
};

// Set component property types.
ErrorAlert.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.shape({
      message: PropTypes.string,
      response: PropTypes.shape({
        data: PropTypes.shape({
          message: PropTypes.string,
        }),
      }),
    }),
    PropTypes.string,
    PropTypes.object,
  ]),
  fallback: PropTypes.string,
  severity: PropTypes.string,
};

// Set component default properties.
ErrorAlert.defaultProps = {
  error: null,
  fallback: 'An error occurred.',
  severity: 'error',
};

// Export component.
export default ErrorAlert;
