/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-18
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, Button, Alert } from '@mui/material';

// Add local dependencies.
import ErrorAlert from '../Common/ErrorAlert';
import PageSpinner from '../Common/PageSpinner';
import { useResetUsernameMutation } from '../../api/authSlice';
import FormText from '../Common/FormText';

/**
 * ResetUsername component.
 * @example
 * return (
 *   <ResetUsername />
 * )
 * @returns {React.ReactElement} component.
 */
const ResetUsername = () => {
  // Set state hooks.
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Set mutation hooks.
  const [resetUsername, { isLoading, error }] = useResetUsernameMutation();

  // Initialize react-hook-form.
  const { handleSubmit, control } = useForm();

  // Handle error state.
  useEffect(() => {
    if (error) {
      console.error('Error: ', error);
      setErrorMessage('Failed to send username reset email. Please try again.');
    }
  }, [error]);

  // Render loading indicator while checking the auth state.
  if (isLoading) return <PageSpinner />;

  /**
   * Handle form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    try {
      await resetUsername({ email: data.email }).unwrap();
      setSuccessMessage('If an account with that email exists, a username reset link has been sent.');
      setErrorMessage(null);
    } catch (err) {
      setErrorMessage('Failed to send username reset email. Please try again.');
      setSuccessMessage(null);
      console.error('Error: ', err);
    }
  };

  /**
   * Handle key press events.
   * @param {Event} event - Key press event.
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1">Reset Username</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, maxWidth: 400 }}>
        {successMessage && <Alert severity="info">{successMessage}</Alert>}
        {errorMessage && <ErrorAlert error={errorMessage} fallback="An error occurred. Please try again." />}
        <FormText
          control={control}
          name="email"
          label="Email"
          type="email"
          autoFocus
          onKeyDown={handleKeyDown}
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'Invalid email format',
            },
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Send Reset Link
        </Button>
      </Box>
    </Box>
  );
};

// Export component.
export default ResetUsername;
