/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-12
 */

// Add third-party dependencies.
import { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

// Add local dependencies.
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { useChatBotMutation } from '../../../api/publicSlice';

/**
 * ChatBot component.
 * @example
 * return (
 *   <ChatBot />
 * )
 * @returns {React.ReactElement} component.
 */
const ChatBot = () => {
  // Set state hooks.
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [conversation, setConversation] = useState([
    {
      id: 1,
      text: "Hi there! You're chatting with Clara, the friendly chat bot from Releve Studios. How can I assist you today?",
      role: 'bot',
    },
  ]);

  // ChatBot mutation.
  const [chatBot, { isLoading, error: botError }] = useChatBotMutation();

  // Set effect hook.
  useEffect(() => {
    if (botError) {
      setError(botError);
    }
  }, [botError]);

  /**
   * Handle message submission.
   */
  const handleMessageSubmit = useCallback(
    async (query) => {
      // User message.
      const newMessage = { id: conversation.length + 1, text: query, role: 'user' };
      setConversation((prevConversation) => [...prevConversation, newMessage]);

      try {
        // Bot response.
        const bot = await chatBot({ query, conversation, session_id: sessionId }).unwrap();
        const botMessage = { id: conversation.length + 2, text: bot.response, role: 'bot' };
        setConversation((prevConversation) => [...prevConversation, botMessage]);

        // Set session ID if not already set
        if (!sessionId && bot.session_id) {
          setSessionId(bot.session_id);
        }
      } catch (err) {
        console.error('Failed to send query:', err);
        setError(err);
      }
    },
    [chatBot, conversation, sessionId]
  );

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1" gutterBottom>
        Ask Clara
      </Typography>
      <MessageList conversation={conversation} isLoading={isLoading} error={error} />
      <MessageInput onMessageSubmit={handleMessageSubmit} />
    </Box>
  );
};

// Export component.
export default ChatBot;
