/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-03-19
 */

// Add third-party dependencies.
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link as RouterLink } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Link as MuiLink,
  Unstable_Grid2 as Grid,
  CircularProgress,
  Alert,
} from '@mui/material';

// Add local dependencies.
import { useGetCampsQuery, useCreateCampsContactMutation } from '../../api/publicSlice';
import PageSpinner from '../Common/PageSpinner';

/**
 * CampsContact component.
 * @example
 * return (
 *   <CampsContact />
 * )
 * @returns {React.ReactElement} component.
 */
const CampsContact = () => {
  // Set theme hook.
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get data info from API.
  const { isLoading, isError, error, isSuccess, data: camps } = useGetCampsQuery();

  // Set mutation hook.
  const [
    submitCampsContactForm,
    { isLoading: isPosting, isSuccess: submitSuccess, isError: isSubmitError, error: submitError },
  ] = useCreateCampsContactMutation();

  // Set form hook.
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      note: '',
      mailing_list: true, // Default to true
      camps: {}, // Object to track checked state by camp id
      recaptcha: '', // Recaptcha token
    },
    mode: 'onBlur',
  });

  // Watch for changes to the camps object.
  const watchedCamps = watch('camps');

  /**
   * Handle the change event of the ReCaptcha component.
   *
   * @param {string} token - The token generated by the ReCaptcha component.
   * @returns {void}
   */
  const onReCaptchaChange = (token) => {
    setValue('recaptcha', token, { shouldValidate: true });
  };

  /**
   * Handle form submission.
   *
   * @param {Object} formData - The form data object.
   * @returns {void}
   */
  const onSubmit = async (formData) => {
    if (!formData.recaptcha) {
      console.error('ReCAPTCHA token is missing.');
      return; // Prevent form submission if reCAPTCHA token is missing
    }

    // Get the checked camp ids and titles.
    const checkedCampIds = Object.keys(watchedCamps)
      .filter((campId) => watchedCamps[campId])
      .map((key) => key.replace('camps.', ''));
    const checkedCampTitles = camps
      .filter((camp) => checkedCampIds.includes(String(camp.id)))
      .map((camp) => camp.title);
    const joinedCampTitles = checkedCampTitles.join(', ');

    // Update formData with the joined camp titles.
    const updatedFormData = {
      ...formData,
      camps: joinedCampTitles, // Replace the camps object/array with a string of joined titles
    };

    try {
      // Assuming submitCampsContactForm is an async function returning a promise.
      await submitCampsContactForm(updatedFormData).unwrap();
    } catch (err) {
      console.error('Submission Error:', err);
    }
  };

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1" gutterBottom>
        Camps Contact
      </Typography>
      {isSubmitError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          We&apos;re sorry, but your form could not be submitted at this time. Please call Relevé Studios at
          1-818-654-4140 for immediate assistance. An error occurred while submitting the form: {submitError.status} -{' '}
          {submitError.data?.message || 'Unknown error'}
        </Alert>
      )}
      {submitSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Thank you for your submission! Someone from our team will contact you within the next couple of business
          days.
        </Alert>
      )}
      {isSubmitSuccessful && (
        <Button variant="contained" component={RouterLink} to="/camps">
          Click here to return to the Camps page
        </Button>
      )}
      {!isSubmitting && !isSubmitSuccessful && (
        <Box>
          <Paper>
            <Typography variant="body2" p={2} m={2} sx={{ fontStyle: 'italic' }}>
              Privacy Policy: We will not share your information with any third party. We may contact you via email to
              share new products, classes, or services unless you ask us not to. You may opt-out at any time.{' '}
              <MuiLink component={RouterLink} to="/privacy-policy">
                Click here for more informaiton.
              </MuiLink>
            </Typography>
          </Paper>
          <Typography gutterBottom>
            Please fill out the form below, and we&apos;ll be in touch to assist with scheduling your summer camps.
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Grid container spacing={isMobile ? 0 : 2}>
              <Grid xs={12} sm={6}>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{ required: 'First name is required' }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      margin="normal"
                      required
                      fullWidth
                      label="First Name"
                      error={!!errors.first_name}
                      helperText={errors.first_name ? errors.first_name.message : ''}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{ required: 'Last name is required' }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      margin="normal"
                      required
                      fullWidth
                      label="Last Name"
                      error={!!errors.last_name}
                      helperText={errors.last_name ? errors.last_name.message : ''}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref }, fieldState }) => (
                    <TextField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      margin="normal"
                      required
                      fullWidth
                      label="Email Address"
                      type="email"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      margin="normal"
                      fullWidth
                      label="Phone Number"
                    />
                  )}
                />
              </Grid>
              <Grid xs={12}>
                <Controller
                  name="note"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      margin="normal"
                      fullWidth
                      label="Note"
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              name="mailing_list"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  control={<Checkbox onChange={onChange} onBlur={onBlur} checked={value} name={name} inputRef={ref} />}
                  label="Add me to the mailing list"
                />
              )}
            />

            {/* For a dynamic list of camps, consider mapping through an array */}
            <Paper sx={{ p: 1 }}>
              {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
              {isError && (
                <Alert severity="error">
                  There was a problem getting info on area attractions: {error?.error || error?.message}
                </Alert>
              )}
              {isSuccess && (
                <>
                  <FormLabel component="legend">I would like information on... (check all that apply)</FormLabel>
                  <FormGroup>
                    <Grid container>
                      {camps.map((camp) => (
                        <Grid xs={12} sm={4} key={camp.id}>
                          <Controller
                            name={`camps.${camp.id}`}
                            control={control}
                            defaultValue={false}
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    checked={value}
                                    name={name}
                                    inputRef={ref}
                                  />
                                }
                                label={camp.title}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </>
              )}
            </Paper>
            <Box sx={{ mt: 2 }}>
              {errors.recaptcha && <Alert severity="error">{errors.recaptcha.message}</Alert>}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size="normal"
                onChange={onReCaptchaChange}
                register={register('recaptcha', { required: 'Please verify you are not a robot' })}
              />
            </Box>
            <Button type="submit" fullWidth variant="contained" disabled={!isValid} sx={{ mt: 3, mb: 2 }}>
              Submit
            </Button>
          </Box>
        </Box>
      )}
      <PageSpinner open={isPosting} />
    </Box>
  );
};

// Export component.
export default CampsContact;
