/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-12
 */

// Add third-party dependencies.
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Button } from '@mui/material';

/**
 * MessageInput component.
 * @example
 * return (
 *   <MessageInput />
 * )
 * @returns {React.ReactElement} component.
 */
const MessageInput = ({ onMessageSubmit }) => {
  // Set state hook.
  const [input, setInput] = useState('');

  /**
   * Handle form submission.
   */
  const handleSubmit = () => {
    if (input.trim()) {
      onMessageSubmit(input);
      setInput('');
    }
  };

  /**
   * Handle key down event.
   * @param {React.KeyboardEvent} event The keyboard event.
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  // Render component.
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        label="Message"
        value={input}
        onChange={(event) => setInput(event.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button variant="contained" onClick={handleSubmit} sx={{ ml: 1 }}>
        Send
      </Button>
    </Box>
  );
};

// Set component property types.
MessageInput.propTypes = {
  onMessageSubmit: PropTypes.func.isRequired,
};

// Export component.
export default MessageInput;
