/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-17
 */

/**
 * This is the navigation menu list data.
 *
 * Icons are imported in Icons.js
 */

/**
 * Create array of objects.
 */

// Main menu items.
const mainMenuItems = [
  { label: 'Home', link: '/', icon: 'Home' },
  { label: 'Contact', link: '/contact', icon: 'Mail' },
  { label: 'Special Offer', link: '/special-offer', icon: 'LocalOffer' },
  { label: 'Register', link: '/register', icon: 'PersonAdd' },
  { label: 'Parent Portal', link: '/parent-portal', icon: 'SupervisorAccount' },
];

// Service menu items.
const serviceMenuItems = [
  { label: 'Schedule', link: '/schedule', icon: 'Schedule', divider: true },
  { label: 'Summer Camps', link: '/camps', icon: 'Sunny' },
  { label: 'Recital Info', link: '/recital-info', icon: 'LocalActivity' },
  { label: 'Recital Videos', link: '/recital-videos/2024', icon: 'Videos' },
];

// Info menu items.
const infoMenuItems = [
  { label: 'About Relevé', link: '/about', icon: 'Info', divider: true },
  { label: 'Location', link: '/location', icon: 'Map' },
  { label: 'Rentals', link: '/rentals', icon: 'Camera' },
  { label: 'Store', link: '/store', icon: 'Store' },
];

// External menu items.
const externalMenuItems = [
  {
    label: 'Little Daisy Scholarhips',
    link: '/little-daisy-fund',
    icon: 'LocalFlorist',
    divider: true,
  },
];

const menuItems = [...mainMenuItems, ...serviceMenuItems, ...infoMenuItems, ...externalMenuItems];

export default () => menuItems;
