/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-16
 */

// Add third-party dependencies.
import { Box } from '@mui/material';

// Add local dependencies.
import Themes from '../../themes';
import Header from './Header';
import Router from '../App/Router';
import Footer from './Footer';

/**
 * Layout component.
 * @example
 * return (
 *   <Layout />
 * )
 * @returns {React.ReactElement} component.
 */
const Layout = () => {
  // Render component.
  return (
    <Themes>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <Header />
        <Box component="main" sx={{ display: 'flex', flex: 1 }}>
          <Router />
        </Box>
        <Footer />
      </Box>
    </Themes>
  );
};

// Export component.
export default Layout;
