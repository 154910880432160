/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-21
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

// Create component map.
const componentMap = {
  Box,
  Typography,
};

/**
 * DynamicComponentRenderer component.
 * @example
 * return (
 *   <DynamicComponentRenderer />
 * )
 * @returns {React.ReactElement} component.
 */
const DynamicComponentRenderer = ({ component, props }) => {
  // Get component.
  const Component = componentMap[component];
  // eslint-disable-next-line react/jsx-props-no-spreading
  return Component ? <Component {...props} /> : null;
};

// Set component property types.
DynamicComponentRenderer.propTypes = {
  component: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  props: PropTypes.object,
};

// Set component default properties.
DynamicComponentRenderer.defaultProps = {};

// Export component.
export default DynamicComponentRenderer;
