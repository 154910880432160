/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-21
 */

// Add third-party dependencies.
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

// Add local dependencies.
import AuthContext from '../../context/AuthContext';
import PageSpinner from '../Common/PageSpinner';

/**
 * PrivateRoute component.
 * @example
 * return (
 *   <PrivateRoute />
 * )
 * @returns {React.ReactElement} component.
 */
const PrivateRoute = ({ element, allowedGroups }) => {
  // Get user.
  const { user, loading } = useContext(AuthContext);

  // console.log('PrivateRoute user:', user);

  // Get location.
  const location = useLocation();

  // Render loading indicator while checking the auth state.
  if (loading) return <PageSpinner />;

  // Redirect to login if user is not authenticated.
  if (!user) return <Navigate to="/login" replace state={{ from: location }} />;

  // If no allowedGroups are provided, allow access by default.
  if (allowedGroups && allowedGroups.length > 0) {
    // Check if the user belongs to any of the allowed groups.
    const userGroups = user.groups || [];
    const isAuthorized = allowedGroups.some((group) => userGroups.includes(group));

    if (!isAuthorized) {
      // Redirect to a "Not Authorized" page or some other fallback.
      return <Navigate to="/not-authorized" replace />;
    }
  }

  // Render component.
  return element;
};

// Set component property types.
PrivateRoute.propTypes = {
  element: PropTypes.element.isRequired,
  allowedGroups: PropTypes.arrayOf(PropTypes.string), // Array of allowed group names
};

PrivateRoute.defaultProps = {
  allowedGroups: [], // No groups are required by default
};

// Export component.
export default PrivateRoute;
