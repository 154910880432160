/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-10-20
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import { useGetDeskActivitiesQuery } from '../../../api/payrollSlice';
import PageSpinner from '../../Common/PageSpinner';
import ErrorAlert from '../../Common/ErrorAlert';
import { parseDateAsLocal, parseDateTimeAsLocal } from '../../../assets/helpers/timecalc';
import { formatTime } from '../../../assets/helpers/format';

/**
 * DeskPay component.
 * @example
 * return (
 *   <DeskPay />
 * )
 * @returns {React.ReactElement} component.
 */
const DeskPay = () => {
  // Set state hooks.
  const [rows, setRows] = useState([]);

  // Set query hooks.
  const {
    data: deskActivities,
    isLoading: isDeskActivitiesLoading,
    error: deskActivitiesError,
  } = useGetDeskActivitiesQuery();

  // Set effect hook.
  useEffect(() => {
    if (deskActivities) setRows(deskActivities);
  }, [deskActivities]);

  // Set column definitions.
  const columns = [
    { field: 'id', headerName: 'ID', type: 'number' },
    { field: 'processed', headerName: 'Processed', type: 'boolean' },
    { field: 'user__first_name', headerName: 'First Name' },
    { field: 'user__last_name', headerName: 'Last Name' },
    { field: 'activity', headerName: 'Activity' },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      valueGetter: (date) => (typeof date === 'string' ? parseDateAsLocal(date) : date),
    },
    { field: 'start_time', headerName: 'Start Time', valueFormatter: (value) => formatTime(value) },
    { field: 'end_time', headerName: 'End Time', valueFormatter: (value) => formatTime(value) },
    { field: 'hours', headerName: 'Hours', type: 'number', valueFormatter: (value) => value.toFixed(2) },
    { field: 'pay_rate__rate_name', headerName: 'Rate Name' },
    { field: 'pay_rate__rate', headerName: 'Rate', valueFormatter: (value) => `$${value.toFixed(2)}` },
    {
      field: 'created_at',
      headerName: 'Created',
      type: 'dateTime',
      valueGetter: (date) => (typeof date === 'string' ? parseDateTimeAsLocal(date) : date),
    },
    {
      field: 'updated_at',
      headerName: 'Updated',
      type: 'dateTime',
      valueGetter: (date) => (typeof date === 'string' ? parseDateTimeAsLocal(date) : date),
    },
  ];

  // Render component.
  return (
    <AuthMenuGroup>
      {isDeskActivitiesLoading && <PageSpinner />}
      <Box>
        <Typography variant="h1">Desk Activity</Typography>
      </Box>
      {deskActivitiesError && <ErrorAlert error={deskActivitiesError} fallback="Unknown error occurred." />}
      {deskActivities && (
        <Box>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            pageSizeOptions={[10, 25, 50, 75, 100]}
            disableMultipleColumnsSorting={false}
            initialState={{
              pagination: { paginationModel: { pageSize: 50 } },
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              },
              filter: {
                filterModel: {
                  items: [{ field: 'processed', operator: 'is', value: 'false' }],
                },
              },
              density: 'compact',
            }}
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
      )}
    </AuthMenuGroup>
  );
};

// Export component.
export default DeskPay;
