/* eslint-disable jsx-a11y/media-has-caption */
/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-04-17
 */

// Add third-party dependencies.
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Alert, useMediaQuery, useTheme } from '@mui/material';

/**
 * VideoPlayer component.
 * @example
 * return (
 *   <VideoPlayer videoFiles={videoFiles} />
 * )
 * @returns {React.ReactElement} component.
 */
const VideoPlayer = ({ videoFiles }) => {
  // Set Ref hook.
  const player = useRef(null);

  // Set state hook.
  const [errorMessage, setErrorMessage] = useState('');

  // Set theme and media query hooks.
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Event handler for video errors
  const handleError = () => {
    const { error } = player.current;
    if (error) {
      console.error('error', error);
      switch (error.code) {
        case error.MEDIA_ERR_ABORTED:
          setErrorMessage('Video playback was aborted.');
          break;
        case error.MEDIA_ERR_NETWORK:
          setErrorMessage('A network error caused the video download to fail.');
          break;
        case error.MEDIA_ERR_DECODE:
          setErrorMessage(
            'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.'
          );
          break;
        case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          setErrorMessage(
            'The video could not be loaded, either because the server or network failed or because the format is not supported.'
          );
          break;
        default:
          setErrorMessage('An unknown error occurred.');
          break;
      }
    }
  };

  // Video sources.
  const sources = videoFiles.map((file) => <source key={file.id} src={encodeURI(file.src)} type={file.type} />);

  // Render component.
  return (
    <Box>
      {errorMessage && (
        <Alert severity="error" style={{ marginBottom: '20px' }}>
          {errorMessage}
        </Alert>
      )}
      <video
        ref={player}
        controls
        id="video"
        playsInline
        onError={handleError}
        style={{
          width: isMobile ? '100%' : 'auto', // 100% width on mobile
          maxHeight: isMobile ? 'none' : '400px', // No max height on mobile, 400px on desktop
          objectFit: 'contain',
        }}
      >
        {sources}
      </video>
    </Box>
  );
};

// Set component property types.
VideoPlayer.propTypes = {
  videoFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      src: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['video/mp4', 'video/webm']).isRequired,
    })
  ).isRequired,
};

// Export component.
export default VideoPlayer;
