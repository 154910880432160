/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-04-16
 */

// Add third-party dependencies.
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, Alert } from '@mui/material';

// Add local dependencies.
import { useGetRecitalVideosQuery } from '../../api/publicSlice';
import Icons from '../Common/Icons';
import VideoPlayer from '../Common/VideoPlayer';

// Set columns.
const columns = [
  {
    field: 'id',
    headerName: 'Play',
    width: 50,
    align: 'center',
    sortable: false,
    filterable: false,
    renderCell: () => (
      <Box pt={1}>
        <Icons iconName="PlayCircleFilled" title="Play" />
      </Box>
    ),
  },
  { field: 'title', headerName: 'Title', width: 250 },
  { field: 'show_day', headerName: 'Show Day', width: 150 },
  { field: 'show_time', headerName: 'Show Time', width: 150 },
  { field: 'teacher', headerName: 'Teacher', width: 150 },
  { field: 'class_name', headerName: 'Class', width: 150 },
  { field: 'team', headerName: 'Team', width: 200 },
];

/**
 * RecitalVideos component.
 * @example
 * return (
 *   <RecitalVideos />
 * )
 * @returns {React.ReactElement} component.
 */
const RecitalVideos = () => {
  // Get environment variables.
  const videoUrl = process.env.REACT_APP_VIDEO_URL;

  // Router hooks.
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname.split('/').filter((t) => t);
  const year = pathParts[1];

  // State for selected video name
  const [videoName, setVideoName] = useState(pathParts.length > 2 ? `${pathParts[2]}/${pathParts[3]}` : undefined);

  // API hooks.
  const { isLoading, isError, error, isSuccess, data } = useGetRecitalVideosQuery(year);

  // Set video name.
  useEffect(() => {
    const newPathParts = pathname.split('/').filter((t) => t);
    setVideoName(newPathParts.length > 2 ? `${newPathParts[2]}/${newPathParts[3]}` : undefined);
  }, [pathname]);

  /**
   * Handle row click event.
   * @param {Object} params - The event parameters.
   * @param {Object} params.row - The clicked row object.
   * @returns {void}
   */
  const handleRowClick = (params) => {
    const { row } = params;
    navigate(`/recital-videos/${year}/recital-${year}/${row.file_name}`);
  };

  // Set video URL.
  const videoFiles = videoName
    ? [
        {
          id: 0,
          src: `${videoUrl}/${videoName}.mp4`,
          type: 'video/mp4',
        },
        {
          id: 1,
          src: `${videoUrl}/${videoName}.webm`,
          type: 'video/webm',
        },
      ]
    : [];

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1">{year} Recital Videos</Typography>
      {isError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error?.error || error?.message || 'There was a problem loading the recital videos.'}
        </Alert>
      )}
      {videoName && <VideoPlayer key={videoName} videoFiles={videoFiles} />}
      {isSuccess && (
        <Box width="100%" m="auto">
          <DataGrid
            columns={columns}
            rows={data}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            onRowClick={handleRowClick}
            loading={isLoading}
            sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' }, minHeight: '455px' }}
            density="compact"
          />
        </Box>
      )}
    </Box>
  );
};

// Export component.
export default RecitalVideos;
