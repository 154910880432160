/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-27
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress } from '@mui/material';

/**
 * PageSpinner component.
 * @example
 * return (
 *   <PageSpinner />
 * )
 * @returns {React.ReactElement} component.
 */
const PageSpinner = ({ open, color, backdrop }) => {
  // Render component.
  if (backdrop) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color={color} />
      </Backdrop>
    );
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <CircularProgress color={color} />
    </Box>
  );
};

// Set component property types.
PageSpinner.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
  backdrop: PropTypes.bool,
};

// Set component default properties.
PageSpinner.defaultProps = {
  open: true,
  color: 'inherit',
  backdrop: true,
};

// Export component.
export default PageSpinner;
