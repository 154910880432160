/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-18
 */

// Add third-party dependencies.
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Typography, Button, Grid, Link as MuiLink, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Add local dependencies.
import ErrorAlert from '../Common/ErrorAlert';
import AuthContext from '../../context/AuthContext';
import FormText from '../Common/FormText';

/**
 * Login component.
 * @example
 * return (
 *   <Login />
 * )
 * @returns {React.ReactElement} component.
 */
const Login = ({ title }) => {
  // Set state hooks.
  const [showPassword, setShowPassword] = useState(false);

  // Set context hook.
  const { login, authError } = useContext(AuthContext);

  // Set navigate hook.
  const navigate = useNavigate();
  const location = useLocation();

  // Set location state.
  const { from } = location.state || { from: { pathname: '/welcome' } };

  // Initialize react-hook-form.
  const { handleSubmit, control, setError } = useForm();

  /**
   * Handle form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    try {
      await login(data);
      console.info('Login successful.');
      navigate(from.pathname, { replace: true });
    } catch (err) {
      setError('root.serverError', {
        type: 'manual',
        message: 'Login failed. Please check your credentials and try again.',
      });
      console.error('Error: ', err);
    }
  };

  /**
   * Handle key press events.
   * @param {Event} event - Key press event.
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1">{title}</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, maxWidth: 400 }}>
        {authError && <ErrorAlert error={authError} fallback="An error occurred during login." />}
        <FormText
          control={control}
          name="username"
          label="Username"
          required
          autoFocus
          rules={{ required: 'Username is required.' }}
        />
        <FormText
          control={control}
          name="password"
          label="Password"
          required
          type={showPassword ? 'text' : 'password'}
          rules={{ required: 'Password is required.' }}
          onKeyDown={handleKeyDown}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <MuiLink component={RouterLink} to="/reset-password" color="textSecondary">
              Forgot password?
            </MuiLink>
          </Grid>
          <Grid item xs sx={{ textAlign: 'right' }}>
            <MuiLink component={RouterLink} to="/reset-username" color="textSecondary">
              Forgot username?
            </MuiLink>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

// Set component property types.
Login.propTypes = {
  title: PropTypes.string,
};

// Set component default properties.
Login.defaultProps = {
  title: 'Login',
};

// Export component.
export default Login;
