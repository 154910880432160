/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-09-23
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, Unstable_Grid2 as Grid, Button } from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import FormText from '../../Common/FormText';
import { useGetAdminPayMutation } from '../../../api/payrollSlice';
import { getTodaysDate } from '../../../assets/helpers/timecalc';
import PageSpinner from '../../Common/PageSpinner';
import ErrorAlert from '../../Common/ErrorAlert';

/**
 * AdminPay component.
 * @example
 * return (
 *   <AdminPay />
 * )
 * @returns {React.ReactElement} component.
 */
const AdminPay = () => {
  // Set state hooks.
  const [defaultFromDate, setDefaultFromDate] = useState('');
  const [defaultToDate, setDefaultToDate] = useState('');

  // Set mutation hooks.
  const [getAdminPay, { isLoading: getAdminPayIsLoading, data: getAdminPayData }] = useGetAdminPayMutation();

  // Set form hooks.
  const { control, handleSubmit, setValue } = useForm();

  // Set effect hook.
  useEffect(() => {
    const today = getTodaysDate();
    const storedFromDate = localStorage.getItem('adminPayFromDate');
    const storedToDate = localStorage.getItem('adminPayToDate');

    const fromDate = storedFromDate || today;
    const toDate = storedToDate || today;

    setDefaultFromDate(fromDate);
    setDefaultToDate(toDate);

    setValue('adminPayFromDate', fromDate); // Set the default value for the from date field
    setValue('adminPayToDate', toDate); // Set the default value for the to date field
  }, [setValue]);

  /**
   * Handle form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    localStorage.setItem('adminPayFromDate', data.adminPayFromDate); // Save the from date to local storage
    localStorage.setItem('adminPayToDate', data.adminPayToDate); // Save the to date to local storage
    await getAdminPay({
      start_date: data.adminPayFromDate,
      end_date: data.adminPayToDate,
    });
  };

  if (defaultFromDate === undefined || defaultToDate === undefined) return null;

  // Render component.
  return (
    <AuthMenuGroup>
      {getAdminPayIsLoading && <PageSpinner />}
      <Box>
        <Typography variant="h1" gutterBottom>
          Admin Pay
        </Typography>
      </Box>
      {getAdminPayData?.error && <ErrorAlert error={getAdminPayData?.error} fallback="Unknown error occurred." />}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ width: '100%' }}>
        <Grid container spacing={1}>
          <Grid xs={5}>
            <FormText
              control={control}
              name="adminPayFromDate"
              label="From Date"
              type="date"
              defaultValue={defaultFromDate}
              rules={{ required: 'From date is required.' }}
              margin="dense"
              shrinkLabel
            />
          </Grid>
          <Grid xs={5}>
            <FormText
              control={control}
              name="adminPayToDate"
              label="To Date"
              type="date"
              defaultValue={defaultToDate}
              rules={{ required: 'To date is required.' }}
              margin="dense"
              shrinkLabel
            />
          </Grid>

          <Grid xs={2}>
            <Button type="submit" fullWidth variant="contained" sx={{ marginTop: 2 }}>
              Get Total
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Typography variant="h2">
          {getAdminPayData?.admin_pay ? `Admin Pay: $${getAdminPayData?.admin_pay}` : ''}
        </Typography>
      </Box>
    </AuthMenuGroup>
  );
};

// Export component.
export default AdminPay;
