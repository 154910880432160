/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-08-24
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

/**
 * Question component.
 * @example
 * return (
 *   <Question />
 * )
 * @returns {React.ReactElement} component.
 */
const Question = ({ title, question, option1, option2, option1Handler, option2Handler, open, onClose }) => {
  // Render component.
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={option1Handler} color="secondary">
          {option1}
        </Button>
        <Button onClick={option2Handler} color="secondary" autoFocus>
          {option2}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Set component property types.
Question.propTypes = {
  title: PropTypes.string,
  question: PropTypes.string,
  option1: PropTypes.string,
  option2: PropTypes.string,
  option1Handler: PropTypes.func,
  option2Handler: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

// Set component default properties.
Question.defaultProps = {
  title: 'Question',
  question: 'Are you sure?',
  option1: 'Yes',
  option2: 'No',
  option1Handler: () => {},
  option2Handler: () => {},
  open: true,
  onClose: () => {},
};

// Export component.
export default Question;
