/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-07
 */

// Add third-party dependencies.
import { Box, Typography, Link as MuiLink, CircularProgress, Alert } from '@mui/material';

// Add local dependencies.
import PdfViewer from '../Common/PdfViewer';
import { useGetPdfQuery } from '../../api/publicSlice';

/**
 * Schedule component.
 * @example
 * return (
 *   <Schedule />
 * )
 * @returns {React.ReactElement} component.
 */
const Schedule = () => {
  // Get data info from API.
  const { isLoading, error, data } = useGetPdfQuery('schedule');

  // Render component.
  return (
    <Box p={2}>
      <Typography variant="h1" gutterBottom>
        Schedule
      </Typography>
      <Typography variant="h4" component="h2">
        Current Schedule
      </Typography>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Alert severity="error">There was a problem getting the schedule info: {error?.error || error?.message}</Alert>
      )}
      {data && (
        <>
          <Typography>
            <MuiLink href={data[0].pdf} color="textSecondary">
              Click here to download the 2023-2024 schedule PDF
            </MuiLink>
          </Typography>
          <PdfViewer pdf={data[0].pdf} />
        </>
      )}
    </Box>
  );
};

// Export component.
export default Schedule;
