/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-30
 */

// Add third-party dependencies.
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Grid, Typography, Link as MuiLink } from '@mui/material';

/**
 * PrivacyPolicy component.
 * @example
 * return (
 *   <PrivacyPolicy />
 * )
 * @returns {React.ReactElement} component.
 */
const PrivacyPolicy = () => {
  // Get theme.
  const theme = useTheme();

  // Set component styles.
  const styles = {
    link: {
      color: theme.palette.secondary.main,
      fontWeight: 600,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  };

  // Render component.
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom>
            Privacy Policy
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h2">
            BY VISITING OUR WEBSITE, YOU ARE CONSENTING TO OUR PRIVACY POLICY.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Overview
          </Typography>
          <Typography gutterBottom>
            Releve Studios is committed to protecting your privacy online. This Privacy Policy describes the personal
            information we collect through this website (the “site” or “sites”), and how we collect and use that
            information. The terms “we,” “us,” and “our” refers to Releve Studios. The terms “user,” “you,” and “your”
            refer to site visitors, customers, and any other users of the site.
          </Typography>
          <Typography gutterBottom>
            The term “personal information” is defined as information that you voluntarily provide to us that
            personally identifies you and/or your contact information, such as your name, phone number, and email
            address. Use of our website, including all materials presented herein and all online services provided by
            Releve Studios is subject to the following Privacy Policy. This Privacy Policy applies to all site
            visitors, customers, and all other users of the site. By using the Site or Service, you agree to this
            Privacy Policy, without modification, and acknowledge reading it.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Information We Collect
          </Typography>
          <Typography gutterBottom>
            Product Orders. We collect a variety of personal information with each product order. This includes:
            <br />
            Your name, address, email address, phone number and other personal information. This information is shared
            with our e-commerce software providers to ensure completion of your order. We use your email to communicate
            with you about your order and to manage our customer relationship with you. When you place an order you may
            be added to our mailing list from which you can unsubscribe at any time using the unsubscribe link in each
            email or by contacting us. Your payment information. We collect payment information for each order but we
            do not store payment information on Releve Studios servers. Your payment information is securely
            communicated to and processed via our e-commerce software providers.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Blog Interaction
          </Typography>
          <Typography gutterBottom>
            We only collect the personal information you voluntarily provide to us which may include:
            <br />
            Your email address in order to subscribe to our mailing list, sign up for a consultation call, or sign up
            for one of our interactive forums; Your name, email address, website in order to post a comment on our
            blog; and your social media profiles in order to share an article from our blog on social media via a third
            party provider; Your full name, email address and message in order to{' '}
            <MuiLink component={RouterLink} to="/contact" sx={styles.link}>
              contact us
            </MuiLink>{' '}
            via our contact form.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Activity
          </Typography>
          <Typography gutterBottom>
            We may record information relating to your use of the Site, such as the searches you undertake, the pages
            you view, your browser type, IP address, requested URL, referring URL, and timestamp information. We use
            this type of information to administer the Site and provide the highest possible level of service to you.
            We also use this information in the aggregate to perform statistical analyses of user behavior and
            characteristics in order to measure interest in and use of the various areas of the Site.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Cookies
          </Typography>
          <Typography gutterBottom>
            We may send cookies to your computer in order to uniquely identify your browser and improve the quality of
            our service. The term “cookies” refers to small pieces of information that a website sends to your
            computer’s hard drive while you are viewing the Site. We may use both session cookies (which expire once
            you close your browser) and persistent cookies (which stay on your computer until you delete them). You
            have the ability to accept or decline cookies using your web browser settings. If you choose to disable
            cookies, some areas of the Site may not work properly or at all.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            How your information is used
          </Typography>
          <Typography gutterBottom>
            The information you provide is used to process transactions, send periodic emails, and improve the service
            we provide. We do share your information with trusted third parties who assist us in operating our website,
            conducting our business and servicing clients and visitors. These trusted third parties agree to keep this
            information confidential. Your personal information will never be shared with unrelated third parties.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Third Party Links
          </Typography>
          <Typography gutterBottom>
            The Site may contain links to third party websites. Except as otherwise discussed in this Privacy Policy,
            this document only addresses the use and disclosure of information we collect from you on our Site. Other
            sites accessible through our site via links or otherwise have their own policies in regard to privacy. We
            are not responsible for the privacy policies or practices of third parties.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Security
          </Typography>
          <Typography gutterBottom>
            We maintain security measures to protect your personal information from unauthorized access, misuse, or
            disclosure. However, no exchange of data over the Internet can be guaranteed as 100% secure. While we make
            every effort to protect your personal information shared with us through our Site, you acknowledge that the
            personal information you voluntarily share with us through this Site could be accessed or tampered with by
            a third party. You agree that we are not responsible for any intercepted information shared through our
            Site without our knowledge or permission. Additionally, you release us from any and all claims arising out
            of or related to the use of such intercepted information in any unauthorized manner.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Children
          </Typography>
          <Typography gutterBottom>
            To access or use the Site, you must be 18 years old or older and have the requisite power and authority to
            enter into this Privacy Policy. Children under the age of 18 are prohibited from using the Site.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Changes to This Policy
          </Typography>
          <Typography gutterBottom>
            You may access and correct your personal information and privacy preferences by contacting us.
            <br />
            If you have questions about our privacy policy, please{' '}
            <MuiLink component={RouterLink} to="/contact" sx={styles.link}>
              contact us
            </MuiLink>
            .
          </Typography>
          <Typography gutterBottom>Updated: August 2019</Typography>
        </Grid>
      </Grid>
    </>
  );
};

// Export component.
export default PrivacyPolicy;
