/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-02-02
 */

// Add third-party dependencies.
import { Grid, Typography } from '@mui/material';

/**
 * RefundPolicy component.
 * @example
 * return (
 *   <RefundPolicy />
 * )
 * @returns {React.ReactElement} component.
 */
const RefundPolicy = () => {
  // Render component.
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom>
            Refund/Return Policy
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h2">
            Overview
          </Typography>
          <Typography gutterBottom>
            There are NO REFUNDS OR CREDITS that will be given for tuition, registration fee, recital tickets, recital
            costumes, and/or any tuition related fees.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Non attendence
          </Typography>
          <Typography gutterBottom>Not attending a class does not excuse not paying for it.</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Adding/Dropping, Absences and Makeups
          </Typography>
          <Typography gutterBottom>
            Two weeks (paid) written notice is required to drop a class. Notice must be given to the front desk.
            Notification to the teacher does not satisfy this requirement. Students and/or parents are responsible for
            monthly payments until a two-week notice is given. If such notice is not provided to Relevé Studios,
            payment is due in full for all classes.
          </Typography>
          <Typography gutterBottom>
            Dancers may be transferred (after enrollment) to class levels more appropriate to their skill levels, as
            determined by the instructor and/or the studio director.
          </Typography>
          <Typography gutterBottom>
            Releve studios must be notified 24 hours in advance for an absence to be considered &ldquo;excused&rdquo;
            and thereby eligible for a make-up class. The make-up class may be used in another dance class within
            thirty (30) days. There is a limit of 8 make-ups per month. Unused classes do not carry over into the next
            session.
          </Typography>
          <Typography gutterBottom>
            There are no credits, refunds, or extensions for holidays or missed classes. Absences, vacations, or
            holidays will not be pro-rated.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="h3">
            Changes to Schedule
          </Typography>
          <Typography gutterBottom>
            Releve Studios reserves the right to cancel or change any class at any time (including instructors).
          </Typography>
          <Typography gutterBottom>Updated: August 2020</Typography>
        </Grid>
      </Grid>
    </>
  );
};

// Export component.
export default RefundPolicy;
