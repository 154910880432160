/* eslint-disable react/jsx-props-no-spreading */
/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-22
 */

// Add third-party dependencies.
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Alert,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import ErrorAlert from '../../Common/ErrorAlert';
import AuthContext from '../../../context/AuthContext';
import { useUpdateMeMutation } from '../../../api/authSlice';
import US_STATES from '../../../assets/constants/states';
import { formatPhoneNumber, formatDate } from '../../../assets/helpers/format';

const ReadOnlyTextField = ({ label, value }) => (
  <Grid item xs={12} sm={6}>
    <Typography sx={{ fontSize: '1.2rem' }}>
      {label}:{' '}
      <Box component="span" sx={{ fontWeight: 'bold' }}>
        {value}
      </Box>
    </Typography>
  </Grid>
);

/**
 * Profile component.
 * @example
 * return (
 *   <Profile />
 * )
 * @returns {React.ReactElement} component.
 */
const Profile = () => {
  // Set context hook.
  const { user, loading, authError } = useContext(AuthContext);

  // Set mutation hook.
  const [updateMe, { isLoading, error, isSuccess }] = useUpdateMeMutation();

  // Set form hook.
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      phone: '',
    },
  });

  // Reset form when user changes.
  useEffect(() => {
    if (user) {
      reset({
        email: user.email || '',
        address1: user.address1 || '',
        address2: user.address2 || '',
        city: user.city || '',
        state: user.state || '',
        postal_code: user.postal_code || '',
        phone: user.phone || '',
      });
    }
  }, [user, reset]);

  // Handle form submission.
  const onSubmit = async (data) => {
    await updateMe(data);
  };

  // Render loading spinner.
  if (loading || isLoading) return <CircularProgress />;

  // Set display name.
  const displayName = user?.display_name || user?.first_name;
  const dba = user?.dba || `${user?.first_name} ${user?.last_name}`;
  const birthDate = user?.birth_date ? formatDate(user.birth_date) : null;
  const startDate = user?.start_date ? formatDate(user.start_date) : null;

  // Render component.
  return (
    <AuthMenuGroup>
      <Typography variant="h1">Profile</Typography>
      {authError && <ErrorAlert error={authError} fallback="Error loading profile." />}
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={2}>
          <ReadOnlyTextField label="Username" value={user?.username} />
          <ReadOnlyTextField label="Display Name" value={displayName} />
          <ReadOnlyTextField label="First Name" value={user?.first_name} />
          <ReadOnlyTextField label="Middle Initial" value={user?.middle_initial} />
          <ReadOnlyTextField label="Last Name" value={user?.last_name} />
          <ReadOnlyTextField label="DBA" value={dba} />
          <ReadOnlyTextField label="Birth Date" value={birthDate} />
          <ReadOnlyTextField label="Start Date" value={startDate} />
          {error && (
            <Grid item xs={12}>
              <ErrorAlert error={error} fallback="Error updating profile." />
            </Grid>
          )}
          {isSuccess && (
            <Grid item xs={12}>
              <Alert severity="success">Profile updated successfully!</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email format',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="address1"
              control={control}
              rules={{ required: 'Address 1 is required' }}
              render={({ field }) => <TextField {...field} label="Address 1" variant="outlined" fullWidth required />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="address2"
              control={control}
              render={({ field }) => <TextField {...field} label="Address 2" variant="outlined" fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Controller
              name="city"
              control={control}
              rules={{ required: 'City is required' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="City"
                  variant="outlined"
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              name="state"
              control={control}
              rules={{ required: 'State is required' }}
              render={({ field }) => (
                <FormControl fullWidth required>
                  <InputLabel id="state-label">State</InputLabel>
                  <Select {...field} labelId="state-label" label="State">
                    {US_STATES.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              name="postal_code"
              control={control}
              rules={{
                required: 'Postal Code is required',
                pattern: {
                  value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                  message: 'Invalid postal code format',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Postal Code"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Controller
              name="phone"
              control={control}
              rules={{ required: 'Phone is required' }}
              render={({ field: { onChange, value, ...field } }) => (
                <TextField
                  {...field}
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  required
                  value={formatPhoneNumber(value)}
                  onChange={(event) => onChange(event.target.value.replace(/[^\d]/g, ''))}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AuthMenuGroup>
  );
};

// Set ReadOnlyTextField property types.
ReadOnlyTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// Set default ReadOnlyTextField property values.
ReadOnlyTextField.defaultProps = {
  value: '',
};

// Export component.
export default Profile;
