/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-16
 */

// Add third-party dependencies.
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';

// Add local dependencies.
import store from './store';
import Layout from '../Layout';

/**
 * App component.
 * @example
 * return (
 *   <App />
 * )
 * @returns {React.ReactElement} component.
 */
const App = () => {
  // Render component.
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Box>
          <Layout />
        </Box>
      </BrowserRouter>
    </Provider>
  );
};

// Export component.
export default App;
