/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-08-17
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from './AuthMenuGroup';
import ErrorAlert from '../Common/ErrorAlert';

/**
 * NotAuthorized component.
 * @example
 * return (
 *   <NotAuthorized />
 * )
 * @returns {React.ReactElement} component.
 */
const NotAuthorized = ({ title, error }) => {
  // Render component.
  return (
    <AuthMenuGroup>
      <Box>
        <Typography variant="h1">{title}</Typography>
        <ErrorAlert error={error} />
      </Box>
    </AuthMenuGroup>
  );
};

// Set component property types.
NotAuthorized.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
};

// Set component default properties.
NotAuthorized.defaultProps = {
  title: 'Not Authorized',
  error: 'You are not authorized to view this page.',
};

// Export component.
export default NotAuthorized;
