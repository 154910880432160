/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-18
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { useTheme, Box, Drawer } from '@mui/material';

/**
 * MobileMenu component.
 * @example
 * return (
 *   <MobileMenu />
 * )
 * @returns {React.ReactElement} component.
 */
const MobileMenu = ({ mobileMenuOpen, setMobileMenuOpen, menuItems }) => {
  // Add theme hooks.
  const theme = useTheme();

  // Render component.
  return (
    <Box
      component="nav"
      aria-label="navigation menu"
      sx={{ [theme.breakpoints.up('sm')]: { width: 240, flexShrink: 0 } }}
    >
      <Drawer anchor="right" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(!mobileMenuOpen)}>
        {menuItems}
      </Drawer>
    </Box>
  );
};

// Set component property types.
MobileMenu.propTypes = {
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired,
  menuItems: PropTypes.element.isRequired,
};

// Export component.
export default MobileMenu;
