/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-17
 */

// Add third-party dependencies.
import { createContext, useState, useMemo, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Add local dependencies.
import { useLoginUserMutation, useRefreshTokenMutation, useVerifyTokenMutation, authSlice } from '../api/authSlice';

// Create the AuthContext.
const AuthContext = createContext();

// Export the Auth provider.
export const AuthProvider = ({ children }) => {
  // Set state hooks.
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  // Create the login mutations.
  const [loginUser] = useLoginUserMutation();
  const [refreshToken] = useRefreshTokenMutation();
  const [verifyToken] = useVerifyTokenMutation();

  // Set the dispatch hook.
  const dispatch = useDispatch();

  // Function to fetch and set the user.
  const fetchUser = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      try {
        setLoading(true);
        const userResponse = await dispatch(authSlice.endpoints.getMe.initiate()).unwrap();
        setUser(userResponse);
        setAuthError(null);
      } catch (error) {
        setUser(null);
        console.error('fetchUser error:', error);
        setAuthError(error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  // Fetch the user on mount and when accessToken changes.
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  // Create the logout function.
  const logout = () => {
    console.info('Logging out.');
    // Remove the tokens from localStorage.
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // Reset the state of the authSlice.
    dispatch(authSlice.util.resetApiState());
    // Reset the user state.
    setUser(null);
  };

  // Function to refresh the token
  useEffect(() => {
    const refreshAuthToken = async () => {
      const refresh = localStorage.getItem('refreshToken');
      if (refresh) {
        try {
          setAuthError(null);
          const response = await refreshToken({ refresh }).unwrap();
          const { access } = response;
          localStorage.setItem('accessToken', access);
          fetchUser();
        } catch (err) {
          console.error('refreshAuthToken error:', err);
          setAuthError(err);
          logout();
        }
      }
    };

    // Set an interval to refresh the token every 2 hours.
    const intervalId = setInterval(refreshAuthToken, 2 * 60 * 60 * 1000);

    // Clear the interval when the component unmounts.
    return () => clearInterval(intervalId);
  }, [refreshToken, fetchUser]);

  // Create the login function.
  const login = async (credentials) => {
    try {
      setAuthError(null);
      const response = await loginUser(credentials).unwrap();
      const { access, refresh } = response;
      // Store the tokens in localStorage.
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      // Fetch the user data after login.
      fetchUser();
      return response;
    } catch (err) {
      console.error('login error:', err);
      setAuthError(err);
      return err;
    }
  };

  // Create the verify token function.
  const verify = async (token) => {
    try {
      setAuthError(null);
      await verifyToken({ token }).unwrap();
      return true;
    } catch (err) {
      console.error('verify error:', err);
      setAuthError(err);
      return false;
    }
  };

  // Return the AuthContext.Provider.
  const authValue = useMemo(
    () => ({ user, loading, authError, login, logout, verify }),
    [user, loading, authError, login, logout, verify]
  );

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

// Set the prop types.
AuthProvider.propTypes = {
  children: propTypes.node.isRequired,
};

// Export AuthContext.
export default AuthContext;
