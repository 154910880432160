/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-16
 */

// Add third-party dependencies.
import { Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';

// Add local dependencies.
import About from '../Pages/About';
import Camps from '../Pages/Camps';
import CampsContact from '../Pages/CampsContact';
import ChatBot from '../Pages/ChatBot';
import Contact from '../Pages/Contact';
import Home from '../Pages/Home';
import Location from '../Pages/Location';
import RecitalInfo from '../Pages/RecitalInfo';
import RecitalVideos from '../Pages/RecitalVideos';
import Rentals from '../Pages/Rentals';
import Schedule from '../Pages/Schedule';
import SpecialOffer from '../Pages/SpecialOffer';
import PrivacyPolicy from '../Legal/PrivacyPolicy';
import TermsOfService from '../Legal/TermsOfService';
import RefundPolicy from '../Legal/RefundPolicy';
import ExternalRedirect from './ExternalRedirect';
import NotFound from './NotFound';
// Authentication components.
import { AuthProvider } from '../../context/AuthContext';
import PrivateRoute from '../Auth/PrivateRoute';
import Login from '../Auth/Login';
import ResetPassword from '../Auth/ResetPassword';
import ResetPasswordConfirm from '../Auth/ResetPasswordConfirm';
import ResetUsername from '../Auth/ResetUsername';
import ResetUsernameConfirm from '../Auth/ResetUsernameConfirm';
import CreateAccount from '../Auth/CreateAccount';
import NotAuthorized from '../Auth/NotAuthorized';
import Logout from '../Auth/Account/Logout';
import Welcome from '../Auth/Account/Welcome';
import Profile from '../Auth/Account/Profile';
import ChnageUsername from '../Auth/Account/ChangeUsername';
import ChangePassword from '../Auth/Account/ChangePassword';
import Timesheet from '../Auth/Employ/Timesheet';
import PayrollReport from '../Auth/Payroll/PayrollReport';
import EditTimesheets from '../Auth/Payroll/EditTimesheets';
import PayrollSummary from '../Auth/Payroll/PayrollSummary';
import DeskActivity from '../Auth/Payroll/DeskActivity';
import AdminPay from '../Auth/Payroll/AdminPay';

/**
 * Router component.
 * @example
 * return (
 *   <Router />
 * )
 * @returns {React.ReactElement} component.
 */
const Router = () => {
  // Set component styles.
  const styles = {
    root: {
      flexGrow: 1,
      overflow: 'auto',
    },
    appBarSpacer: { height: 95 },
  };

  // Render component.
  return (
    <AuthProvider>
      <Box sx={styles.root}>
        <Box id="AppBarSpacer" sx={styles.appBarSpacer} />
        <Routes>
          {/* Public routes. */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/camps" element={<Camps />} />
          <Route path="/camps-contact" element={<CampsContact />} />
          <Route path="/chat-bot" element={<ChatBot />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/location" element={<Location />} />
          <Route path="/recital-info" element={<RecitalInfo />} />
          <Route path="/recital-videos/:year" element={<RecitalVideos />} />
          <Route path="/recital-videos/:year/:folder/:file-name" element={<RecitalVideos />} />
          <Route path="/rentals" element={<Rentals />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/special-offer" element={<SpecialOffer />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route
            path="/register"
            element={<ExternalRedirect to="https://dancestudio-pro.com/online/signup.php?account_id=9411" />}
          />
          <Route
            path="/parent-portal"
            element={<ExternalRedirect to="https://dancestudio-pro.com/online/releve5678" />}
          />
          <Route path="/store" element={<ExternalRedirect to="https://3dayprints.com/releve_studios_/shop/home" />} />
          <Route path="/little-daisy-fund" element={<ExternalRedirect to="http://littledaisy.org/" />} />
          <Route path="*" element={<NotFound />} />
          {/* Auth routes. */}
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
          <Route path="/reset-username" element={<ResetUsername />} />
          <Route path="/username/reset/confirm/:uid/:token" element={<ResetUsernameConfirm />} />
          <Route path="/not-authorized" element={<NotAuthorized />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/welcome" element={<PrivateRoute element={<Welcome />} />} />
          <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
          <Route path="/change-username" element={<PrivateRoute element={<ChnageUsername />} />} />
          <Route path="/change-password" element={<PrivateRoute element={<ChangePassword />} />} />
          <Route path="/timesheet" element={<PrivateRoute element={<Timesheet />} />} />
          <Route
            path="/payroll-report"
            element={<PrivateRoute allowedGroups={['Payroll', 'Owners']} element={<PayrollReport />} />}
          />
          <Route
            path="/edit-timesheets"
            element={<PrivateRoute allowedGroups={['Payroll', 'Owners']} element={<EditTimesheets />} />}
          />
          <Route
            path="/payroll-summary"
            element={<PrivateRoute allowedGroups={['Payroll', 'Owners']} element={<PayrollSummary />} />}
          />
          <Route
            path="/desk-activity"
            element={<PrivateRoute allowedGroups={['Payroll', 'Owners']} element={<DeskActivity />} />}
          />
          <Route
            path="/admin-pay"
            element={<PrivateRoute allowedGroups={['Payroll', 'Owners']} element={<AdminPay />} />}
          />
        </Routes>
      </Box>
    </AuthProvider>
  );
};

// Export component.
export default Router;
