/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-01-17
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVimeoV, faYelp } from '@fortawesome/free-brands-svg-icons';
import {
  Camera as CameraIcon,
  Close as CloseIcon,
  Copyright as CopyrightIcon,
  Facebook as FacebookIcon,
  EventAvailable as EventAvailableIcon,
  ExitToApp as ExitToAppIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Healing as HealingIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  Instagram as InstagramIcon,
  LocalActivity as LocalActivityIcon,
  LocalFlorist as LocalFloristIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
  LocalOffer as LocalOfferIcon,
  Mail as MailIcon,
  Map as MapIcon,
  Menu as MenuIcon,
  OndemandVideo as OndemandVideoIcon,
  PersonAdd as PersonAddIcon,
  Phone as PhoneIcon,
  PictureAsPdf as PictureAsPdfIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  Portrait as PortraitIcon,
  QuestionMark as QuestionMarkIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Room as RoomIcon,
  Schedule as ScheduleIcon,
  School as SchoolIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Twitter as TwitterIcon,
  Update as UpdateIcon,
  VideoLibrary as VideoLibraryIcon,
  WbSunny as WbSunnyIcon,
  YouTube as YouTubeIcon,
} from '@mui/icons-material';

/**
 * Icons component.
 * @example
 * return (
 *   <Icons />
 * )
 * @returns {React.ReactElement} component.
 */
const Icons = ({ iconName, title }) => {
  // Icon mapping
  const iconMapping = {
    Camera: <CameraIcon />,
    Close: <CloseIcon />,
    Copyright: <CopyrightIcon />,
    EventAvailable: <EventAvailableIcon />,
    ExitToApp: <ExitToAppIcon />,
    ExpandLess: <ExpandLessIcon />,
    ExpandMore: <ExpandMoreIcon />,
    Facebook: <FacebookIcon />,
    Healing: <HealingIcon />,
    Home: <HomeIcon />,
    Info: <InfoIcon />,
    Instagram: <InstagramIcon />,
    LocalActivity: <LocalActivityIcon />,
    LocalFlorist: <LocalFloristIcon />,
    LocalGroceryStore: <LocalGroceryStoreIcon />,
    LocalOffer: <LocalOfferIcon />,
    Mail: <MailIcon />,
    Map: <MapIcon />,
    Menu: <MenuIcon />,
    OndemandVideo: <OndemandVideoIcon />,
    PersonAdd: <PersonAddIcon />,
    Phone: <PhoneIcon />,
    PlayCircleFilled: <PlayCircleFilledIcon />,
    PictureAsPdf: <PictureAsPdfIcon />,
    QuestionMark: <QuestionMarkIcon />,
    Portrait: <PortraitIcon />,
    RecordVoiceOver: <RecordVoiceOverIcon />,
    Room: <RoomIcon title={title} />,
    Schedule: <ScheduleIcon />,
    School: <SchoolIcon />,
    Store: <LocalGroceryStoreIcon />,
    Sunny: <WbSunnyIcon />,
    SupervisorAccount: <SupervisorAccountIcon />,
    Twitter: <TwitterIcon />,
    Update: <UpdateIcon />,
    VideoLibrary: <VideoLibraryIcon />,
    Videos: <VideoLibraryIcon />,
    Vimeo: <FontAwesomeIcon icon={faVimeoV} />,
    WbSunny: <WbSunnyIcon />,
    Yelp: <FontAwesomeIcon icon={faYelp} />,
    YouTube: <YouTubeIcon />,
  };

  // Return icon.
  return iconMapping[iconName] || <QuestionMarkIcon />;
};

// Set component property types.
Icons.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string,
};

// Set default properties.
Icons.defaultProps = {
  title: '',
};

// Export component.
export default Icons;
