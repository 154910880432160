/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-12
 */

// Add third-party dependencies.
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import { Box, Typography, Link as MuiLink } from '@mui/material';

// Add local dependencies.
import floorPlan from '../../assets/images/floor-plan.png';

/**
 * Rentals component.
 * @example
 * return (
 *   <Rentals />
 * )
 * @returns {React.ReactElement} component.
 */
const Rentals = () => {
  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1" gutterBottom>
        Rentals
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Our studios are available for one time or recurring rental. For availability and more information please{' '}
        <MuiLink component={RouterLink} to="/contact" color="textSecondary">
          contact us
        </MuiLink>
        .
      </Typography>
      <Image src={floorPlan} alt="Floor plan of Relevé Studios" />
    </Box>
  );
};

// Export component.
export default Rentals;
