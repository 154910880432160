/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-24
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, Alert, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import ErrorAlert from '../../Common/ErrorAlert';
import PageSpinner from '../../Common/PageSpinner';
import { useSetUsernameMutation } from '../../../api/authSlice';
import FormText from '../../Common/FormText';

/**
 * ChangeUsername component.
 * @example
 * return (
 *   <ChangeUsername />
 * )
 * @returns {React.ReactElement} component.
 */
const ChangeUsername = () => {
  // Set state hooks.
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  // Set mutation hooks.
  const [setUsername, { isLoading, error }] = useSetUsernameMutation();

  // Initialize react-hook-form.
  const { handleSubmit, control } = useForm();

  // Handle error state.
  useEffect(() => {
    if (error) {
      console.error('Error', error);
      setErrorMessage(error?.data?.current_password[0] || 'Failed to set new username. Please try again.');
      setSuccessMessage(null);
    }
  }, [error]);

  // Render loading indicator while checking the auth state.
  if (isLoading) return <PageSpinner />;

  /**
   * Handle username form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    try {
      await setUsername({ currentPassword: data.currentPassword, newUsername: data.newUsername });
      setSuccessMessage('Username successfully updated.');
      setErrorMessage(null);
    } catch (err) {
      console.error('Error', err);
      setErrorMessage('Failed to set new username. Please try again.');
      setSuccessMessage(null);
    }
  };

  /**
   * Handle key press events.
   * @param {Event} event - Key press event.
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  // Render component.
  return (
    <AuthMenuGroup>
      <Typography variant="h1">Change Username</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3, maxWidth: 400 }}>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <ErrorAlert error={errorMessage} fallback="An error occurred. Please try again." />}
        <FormText
          control={control}
          name="currentPassword"
          label="Current Password"
          type={showCurrentPassword ? 'text' : 'password'}
          autoFocus
          rules={{ required: 'Current Password is required' }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle current password visibility"
                  onClick={() => setShowCurrentPassword((prev) => !prev)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormText
          control={control}
          name="newUsername"
          label="New Username"
          rules={{ required: 'New Username is required' }}
          onKeyDown={handleKeyDown}
          autoComplete="new-username"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Change Username
        </Button>
      </Box>
    </AuthMenuGroup>
  );
};

// Export component.
export default ChangeUsername;
