/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-07
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

/**
 * PdfViewer component.
 * @example
 * return (
 *   <PdfViewer pdf="something.pdf" />
 * )
 * @returns {React.ReactElement} component.
 */
const PdfViewer = ({ pdf }) => {
  // Render component.
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box component="iframe" src={pdf} width="100%" height="100%" title="PDF Viewer" />
    </Box>
  );
};

// Set component property types.
PdfViewer.propTypes = {
  pdf: PropTypes.string.isRequired,
};

// Export component.
export default PdfViewer;
