/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-07
 */

// Add third-party dependencies.
import Image from 'mui-image';
import { useTheme, useMediaQuery, Box, Paper, Typography } from '@mui/material';

// Add local dependences.
import releveStudiosBg from '../../assets/images/releve-studios-bg.png';
import studioOne from '../../assets/images/studio-one.png';
import SpecialOfferWidget from '../Common/SpecialOfferWidget';

/**
 * SpecialOffer component.
 * @example
 * return (
 *   <SpecialOffer />
 * )
 * @returns {React.ReactElement} component.
 */
const SpecialOffer = () => {
  // Get theme.
  const theme = useTheme();

  // Use the useMediaQuery hook with the theme's breakpoints.
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Set component styles.
  const styles = {
    root: {
      [theme.breakpoints.up('md')]: {
        bgcolor: theme.palette.primary.main,
        backgroundImage: `url(${releveStudiosBg})`,
        width: '100%',
        height: 'calc(100vh - 95px - 101.3px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundAttachment: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1,
        paddingTop: '16px',
        overflowX: 'hidden',
      },
    },
    container: {
      width: '100%',
      textAlign: 'center',
    },
    photoPaper: {
      position: 'relative',
    },
    photoCredit: {
      position: 'absolute',
      left: 2,
      color: theme.palette.common.white,
      fontSize: '0.5rem',
      [theme.breakpoints.down('md')]: {
        bottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        bottom: 100,
      },
    },
    glow: {
      textShadow: '-1px -1px 2px #ffffff, 0 0 25px #ffffff, 0 0 5px #ffffff',
    },
  };

  // Render component.
  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Typography variant="h1" gutterBottom sx={styles.glow}>
          Special Offer
        </Typography>
        <Typography variant="h3" component="h2" sx={styles.glow}>
          Get a complimentary class pass, so that you come try out our studio for FREE!
        </Typography>
        <SpecialOfferWidget />
      </Box>
      {isMobile && (
        <Box my={3}>
          <Paper elevation={3} sx={styles.photoPaper}>
            <Image src={studioOne} alt="Studio One" />
            <Typography variant="body2" sx={styles.photoCredit}>
              Photography by Amy Bartlam
              <br />
              Interior Architectural Design and Construction by Four Point Design Build Inc
            </Typography>
          </Paper>
        </Box>
      )}
      {!isMobile && (
        <Box>
          <Typography variant="body2" sx={styles.photoCredit}>
            Photography by Amy Bartlam
            <br />
            Interior Architectural Design and Construction by Four Point Design Build Inc
          </Typography>
        </Box>
      )}
    </Box>
  );
};

// Export component.
export default SpecialOffer;
