/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-24
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Typography, Button } from '@mui/material';

// Add local dependencies.
import { useResetUsernameConfirmMutation } from '../../api/authSlice';
import ErrorAlert from '../Common/ErrorAlert';
import PageSpinner from '../Common/PageSpinner';
import FormText from '../Common/FormText';

/**
 * ResetUsernameConfirm component.
 * @example
 * return (
 *   <ResetUsernameConfirm />
 * )
 * @returns {React.ReactElement} component.
 */
const ResetUsernameConfirm = () => {
  // Set state hooks.
  const { uid, token } = useParams();
  const [error, setError] = useState(null);

  // Set mutation hooks.
  const [resetUsernameConfirm, { isLoading, error: resetError }] = useResetUsernameConfirmMutation();

  // Set navigate hook.
  const navigate = useNavigate();

  // Initialize react-hook-form.
  const { handleSubmit, control } = useForm();

  // Set error message.
  useEffect(() => {
    if (resetError) {
      console.error('Error: ', resetError);
      setError(resetError?.data?.new_username[0] || 'Failed to reset username. Please try again.');
    }
  }, [resetError]);

  // Render loading indicator while checking the auth state.
  if (isLoading) return <PageSpinner />;

  /**
   * Handle form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    try {
      await resetUsernameConfirm({ uid, token, newUsername: data.newUsername }).unwrap();
      navigate('/login');
    } catch (err) {
      setError('Failed to reset username. Please try again.');
      console.error('Error: ', err);
    }
  };

  /**
   * Handle key press events.
   * @param {Event} event - Key press event.
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1">Reset Username</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, maxWidth: 400 }}>
        {error && <ErrorAlert error={error} fallback="An error occurred. Please try again." />}
        <FormText
          control={control}
          name="newUsername"
          label="New Username"
          autoFocus
          rules={{ required: 'New Username is required' }}
          onKeyDown={handleKeyDown}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Reset Username
        </Button>
      </Box>
    </Box>
  );
};

// Export component.
export default ResetUsernameConfirm;
