/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-09
 */

// Add third-party dependencies.
import { useEffect } from 'react';
import Iframe from 'react-iframe';
import { useTheme, Box } from '@mui/material';

/**
 * SpecialOfferWidget component.
 * @example
 * return (
 *   <SpecialOfferWidget />
 * )
 * @returns {React.ReactElement} component.
 */
const SpecialOfferWidget = () => {
  // Get theme.
  const theme = useTheme();

  // Set component styles.
  const styles = {
    forceHeight: {
      height: 640,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    formFrame: {
      backgroundColor: theme.palette.background.paper,
      maxWidth: 557,
      margin: 'auto',
      padding: theme.spacing(2),
    },
  };

  // Inject the form embed script.
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://link.dncestudio.com/js/form_embed.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Set the form embed iframe attributes.
  useEffect(() => {
    const iframe = document.getElementById('inline-z0OO6EUqgzFOStV0ZH1H');
    iframe.setAttribute('data-layout', "{'id':'INLINE'}");
    iframe.setAttribute('data-trigger-type', 'alwaysShow');
    iframe.setAttribute('data-trigger-value', '');
    iframe.setAttribute('data-activation-type', 'alwaysActivated');
    iframe.setAttribute('data-activation-value', '');
    iframe.setAttribute('data-deactivation-type', 'neverDeactivate');
    iframe.setAttribute('data-deactivation-value', '');
    iframe.setAttribute('data-form-name', 'Website - 1 Free Class');
    iframe.setAttribute('data-height', '441');
    iframe.setAttribute('data-layout-iframe-id', 'inline-z0OO6EUqgzFOStV0ZH1H');
    iframe.setAttribute('data-form-id', 'z0OO6EUqgzFOStV0ZH1H');
  }, []);

  // Set the form embed iframe styles.
  useEffect(() => {
    const iframeElement = document.getElementById('inline-z0OO6EUqgzFOStV0ZH1H');
    if (iframeElement) {
      iframeElement.style.display = 'inline-block';
      iframeElement.style.width = '100%';
    }
  }, []);

  // Render component.
  return (
    <Box sx={styles.forceHeight}>
      <Box sx={styles.formFrame}>
        <Iframe
          src="https://link.dncestudio.com/widget/form/z0OO6EUqgzFOStV0ZH1H"
          style={{ width: '100%', height: '100%', border: 'none', borderRadius: '0px' }}
          id="inline-z0OO6EUqgzFOStV0ZH1H"
          title="Website - 1 Free Class"
        />
      </Box>
    </Box>
  );
};

// Export component.
export default SpecialOfferWidget;
