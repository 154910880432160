/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-12
 */

/**
 * This is the Map component.
 */

// Add third-party dependencies.
import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMapGl, { Marker, Popup, NavigationControl } from 'react-map-gl';
import { Box, Typography, IconButton } from '@mui/material';

// Add local dependencies.
import useEventListener from './useEventListener';
import Icons from './Icons';

// Required token (https://www.mapbox.com/).
const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

/**
 * Map component.
 * @example
 * return (
 *   <Map />
 * )
 * @returns {React.ReactElement} component.
 */
const Map = ({ addresses, mapCenterLatitude, mapCenterLongitude, width, height, zoom, resize }) => {
  // Refs.
  const refContainer = useRef(null);

  // State.
  const [viewPort, setViewPort] = useState({
    latitude: mapCenterLatitude,
    longitude: mapCenterLongitude,
    width,
    height,
    zoom,
  });
  const [mounted, setMounted] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(addresses[0] || {});
  const [showPopup, setShowPopup] = useState(false);

  // Effect hooks.
  useEffect(() => setMounted(true), []);
  useEffect(() => {
    if (resize) {
      const newViewPort = {
        ...viewPort,
        width: refContainer.current.clientWidth,
        height: refContainer.current.clientHeight,
      };
      if (viewPort.width !== newViewPort.width || viewPort.height !== newViewPort.height) setViewPort(newViewPort);
    }
  }, [resize, refContainer, viewPort]);

  /**
   * Handle map click.
   * @param {Object} event
   */
  const handleMapClick = (event) => {
    event.preventDefault();
    console.info('Latitude:', event.lngLat.lat, 'Longitude:', event.lngLat.lng);
  };

  /**
   * Handle marker click.
   * @param {Object} event
   */
  const handleMarkerClick = (address) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedMarker(address);
    setShowPopup(true);
  };

  /**
   * Handle popup close.
   */
  const handlePopupClose = () => {
    setShowPopup(false);
  };

  /**
   * Handle key press.
   * @param {Object} event
   */
  const handleKey = (event) => {
    if (event.key === 'Escape') setSelectedMarker(null);
  };

  // Add event listener.
  useEventListener('keydown', handleKey);

  // Loop addresses.
  const markers = addresses.map((address) => (
    <Marker key={address.id} latitude={address.latitude} longitude={address.longitude} anchor="bottom" offset={[0, 5]}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <IconButton style={{ border: 'black' }} aria-label="details" onClick={handleMarkerClick(address)}>
          <Icons iconName="Room" title={address.label} />
        </IconButton>
        <Typography
          align="center"
          sx={{ transform: 'translate(0, -5px)', background: 'rgba(255, 255, 255, 0.7)', fontSize: 14 }}
        >
          {address.label.split('|').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ))}
        </Typography>
      </Box>
    </Marker>
  ));

  /**
   * Format popup label.
   * @param {Object} popup - Popup object.
   */
  const formatPopup = (popup) => {
    const label = popup.label || popup;
    // Add line breaks.
    let text = label.split('|').map((line) => (
      <span key={line}>
        {line}
        <br />
      </span>
    ));
    // Add link.
    text = popup.url ? <a href={popup.url}>{text}</a> : text;
    return text;
  };

  return (
    <Box className="map" ref={refContainer}>
      <ReactMapGl
        mapboxAccessToken={TOKEN}
        initialViewState={{
          longitude: viewPort.longitude,
          latitude: viewPort.latitude,
          zoom: viewPort.zoom,
        }}
        style={{ width: viewPort.width, height: viewPort.height }}
        // https://studio.mapbox.com/
        // Old purble
        // mapStyle="mapbox://styles/shonlittle/ck115z4aw0dy41dpv8r1hpf82"
        // New white
        mapStyle="mapbox://styles/shonlittle/cklzj1aio0xab17n1vt0rpvvu"
        onViewportChange={(viewport) => {
          if (mounted) {
            setViewPort(viewport);
          }
        }}
        onClick={handleMapClick}
      >
        <NavigationControl style={{ right: 10, top: 10 }} />
        {markers}
        {showPopup && (
          <Popup
            latitude={selectedMarker.latitude}
            longitude={selectedMarker.longitude}
            anchor="bottom"
            offset={[0, -35]}
            onClose={handlePopupClose}
          >
            <Box>{formatPopup(selectedMarker.popup)}</Box>
          </Popup>
        )}
      </ReactMapGl>
    </Box>
  );
};

// Set component property types.
Map.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      checked: PropTypes.bool.isRequired,
      category: PropTypes.string.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      popup_url: PropTypes.string,
      popup_label: PropTypes.string,
    })
  ).isRequired,
  mapCenterLatitude: PropTypes.number.isRequired,
  mapCenterLongitude: PropTypes.number.isRequired,
  width: PropTypes.string,
  height: PropTypes.number,
  zoom: PropTypes.number,
  resize: PropTypes.bool,
};

// Set component default properties.
Map.defaultProps = {
  width: '100%',
  height: 600,
  zoom: 16.4,
  resize: true,
};

// Export component.
export default Map;
