/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-11
 */

// Add third-party dependencies.
import { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * ExternalRedirect component.
 * @example
 * return (
 *   <ExternalRedirect to="https://www.example.com" />
 * )
 * @param {string} props.to - The URL to redirect to.
 * @returns {React.ReactElement} component.
 */
const ExternalRedirect = ({ to }) => {
  // Redirect the user to the external URL.
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  // Component does not render anything.
  return null;
};

// Set component property types.
ExternalRedirect.propTypes = {
  to: PropTypes.string.isRequired,
};

// Export component.
export default ExternalRedirect;
