/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-12
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Typography,
  Link as MuiLink,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Alert,
} from '@mui/material';

// Add local dependencies.
import Map from '../Common/Map';
import Icons from '../Common/Icons';
import { useGetPointOfInterestQuery } from '../../api/publicSlice';

/**
 * Location component.
 * @example
 * return (
 *   <Location />
 * )
 * @returns {React.ReactElement} component.
 */
const Location = () => {
  // Get points of interest.
  const { isLoading, isSuccess, isError, error, data } = useGetPointOfInterestQuery();

  // Categories.
  const [categories, setCategories] = useState([
    { id: 0, type: 'category', checked: true, indeterminate: false, label: 'Relevé Studios' },
    { id: 1, type: 'category', checked: false, indeterminate: false, label: 'Dining' },
    { id: 2, type: 'category', checked: false, indeterminate: false, label: 'Shopping' },
    { id: 3, type: 'category', checked: false, indeterminate: false, label: 'Services' },
  ]);

  // Area points of interest.
  const [pois, setPois] = useState([
    {
      id: 1,
      checked: true,
      category: 'Relevé Studios',
      latitude: 34.22946395627042,
      longitude: -118.56099365231327,
      label: 'Relevé',
      popup_label:
        'Relevé Studios|in the Northridge Plaza Shopping Center|located at 8766 Corbin Ave|Northridge, CA 91324',
    },
  ]);

  // Set effect hook for API point of interest data.
  useEffect(() => {
    if (isSuccess) setPois(data);
  }, [isSuccess, data]);

  /**
   * Category change event handler.
   */
  const handleCategoryChange = (e) => {
    // Update category.
    let { id } = e.target;
    id = id.replace('checkbox-', '');
    const category = categories.filter((c) => c.id === parseInt(id, 10))[0];
    const checked = !category.checked;
    const nextStateCategory = categories.map((c) => (c.id === parseInt(id, 10) ? { ...c, checked } : c));
    setCategories(nextStateCategory);
    // Update POIs.
    const nextStatePoi = pois.map((p) => (p.category.includes(category.label) ? { ...p, checked } : p));
    setPois(nextStatePoi);
  };

  /**
   * POI change event handler.
   */
  const handlePoiChange = (e, category, id) => {
    // Update POI.
    const nextStatePoi = pois.map((p) => (p.id === parseInt(id, 10) ? { ...p, checked: !p.checked } : p));
    setPois(nextStatePoi);
    // Update category.
    const parentCategory = categories.filter((c) => c.label === category)[0];
    const poisAll = nextStatePoi.filter((p) => p.category.includes(category)).length;
    const poisChecked = nextStatePoi.filter((p) => p.category.includes(category) && p.checked).length;
    let checked = false;
    let indeterminate = false;
    if (poisAll === poisChecked && poisChecked > 0) checked = true;
    else if (poisChecked > 0) indeterminate = true;
    const nextStateCategory = categories.map((c) =>
      c.id === parentCategory.id ? { ...c, checked, indeterminate } : c
    );
    setCategories(nextStateCategory);
  };

  // Build check boxes.
  const checkBoxes = [];
  categories.forEach((category) => {
    // Category checkbox.
    const categoryBox = (
      <FormControlLabel
        key={category.id}
        control={
          <Checkbox
            checked={category.checked}
            value={category.id}
            name={category.label}
            id={`checkbox-${category.id}`}
            onChange={handleCategoryChange}
            label={category.label}
            indeterminate={category.indeterminate}
          />
        }
        label={category.label}
      />
    );
    // POI checkbox.
    const categoryPois = pois.filter((p) => p.category.includes(category.label));
    const poiBoxes = [];
    categoryPois.forEach((poi) => {
      poiBoxes.push(
        <FormControlLabel
          pl={2}
          key={`${category.id}-${poi.id}`}
          control={
            <Checkbox
              checked={poi.checked}
              value={poi.id}
              name={poi.label}
              data-identity={poi.id}
              onChange={(e) => handlePoiChange(e, category.label, poi.id)}
              label={poi.label}
            />
          }
          label={poi.label}
        />
      );
    });
    checkBoxes.push(
      <Accordion key={category.id}>
        <AccordionSummary
          expandIcon={<Icons iconName="ExpandMore" />}
          aria-controls={`panel${category.id}-content`}
          id={`panel${category.id}-header`}
        >
          {categoryBox}
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexWrap: 'wrap' }}>{poiBoxes}</AccordionDetails>
      </Accordion>
    );
  });

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1">Location</Typography>
      <Typography>
        Relev&eacute; Studios is located in the Northridge Plaza Shopping Center at 8766 Corbin Ave, Northridge, CA
        91324
      </Typography>
      <Map
        id={0}
        mapCenterLatitude={34.2301}
        mapCenterLongitude={-118.56099}
        width="100%"
        height={600}
        resize
        zoom={16.4}
        addresses={pois.filter((p) => p.checked)}
      />
      <Typography sx={{ fontSize: '1.5rem' }}>Area attractions</Typography>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Alert severity="error">
          There was a problem getting info on area attractions: {error?.error || error?.message}
        </Alert>
      )}
      {isSuccess && <Box sx={{ width: '100%', mt: 1, mb: 2 }}>{checkBoxes}</Box>}
      <Typography>
        If you have any questions about any of this information please{' '}
        <MuiLink component={RouterLink} to="/contact" color="textSecondary">
          contact us
        </MuiLink>
        .
      </Typography>
    </Box>
  );
};

// Export component.
export default Location;
