/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-01-16
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { useTheme, createTheme, useMediaQuery, ThemeProvider, CssBaseline } from '@mui/material';

// Add local dependencies.
import themeReleve from './releve';
import themeMobile from './mobile';

// Create theme.
const defaultTheme = createTheme(themeReleve);
const mobileTheme = createTheme(themeMobile);

/**
 * Themes component.
 * @example
 * return (
 *   <Themes />
 * )
 * @returns {React.ReactElement} component.
 */
const Themes = ({ children }) => {
  // Access the theme
  const theme = useTheme();

  // Use the useMediaQuery hook with the theme's breakpoints.
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Merge the default theme with mobile overrides if on a mobile device
  const appliedTheme = isMobile ? mobileTheme : defaultTheme;

  // Render component.
  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

// Set component property types.
Themes.propTypes = {
  children: PropTypes.node.isRequired,
};

// Export component.
export default Themes;
