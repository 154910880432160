/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-19
 */

// Add third-party dependencies.
import { configureStore } from '@reduxjs/toolkit';

// Add local dependencies.
import { publicSlice } from '../../api/publicSlice';
import { authSlice } from '../../api/authSlice';
import { employSlice } from '../../api/employSlice';
import { payrollSlice } from '../../api/payrollSlice';

// Create the store.
const store = configureStore({
  reducer: {
    // Add the public slice reducer.
    [publicSlice.reducerPath]: publicSlice.reducer,
    // Add the auth slice reducer.
    [authSlice.reducerPath]: authSlice.reducer,
    // Add the employ slice reducer.
    [employSlice.reducerPath]: employSlice.reducer,
    // Add the payroll slice reducer.
    [payrollSlice.reducerPath]: payrollSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      publicSlice.middleware,
      authSlice.middleware,
      employSlice.middleware,
      payrollSlice.middleware
    ),
});

// Export the store.
export default store;
