/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-21
 */

// Add third-party dependencies.
import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

// Add local dependencies.
import AuthContext from '../../../context/AuthContext';

/**
 * Logout component.
 * @example
 * return (
 *   <Logout />
 * )
 * @returns {React.ReactElement} component.
 */
const Logout = ({ title }) => {
  // Get user and logout function.
  const { logout } = useContext(AuthContext);

  // Set navigate hook.
  const navigate = useNavigate();

  // Logout user and redirect to login page.
  useEffect(() => {
    // Logout user.
    logout();
    // Redirect to login page.
    navigate('/login');
  }, [logout, navigate]);
  // Render component.
  return (
    <Box>
      <Typography variant="h1">{title}</Typography>
    </Box>
  );
};

// Set component property types.
Logout.propTypes = {
  title: PropTypes.string,
};

// Set component default properties.
Logout.defaultProps = {
  title: 'Logout',
};

// Export component.
export default Logout;
