/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-02-21
 */

// Add third-party dependencies.
import Image from 'mui-image';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Unstable_Grid2 as Grid, CircularProgress, Alert, Link as MuiLink } from '@mui/material';

// Add local dependencies.
import recital from '../../assets/images/recital.png';
import DynamicComponentRenderer from '../Common/DynamicComponentRenderer';
import { useGetDynamicComponentsForPageQuery } from '../../api/publicSlice';

/**
 * RecitalInfo component.
 * @example
 * return (
 *   <RecitalInfo />
 * )
 * @returns {React.ReactElement} component.
 */
const RecitalInfo = () => {
  // Get recital info.
  const { isLoading, isSuccess, isError, error, data } = useGetDynamicComponentsForPageQuery('recital-info');

  if (isError) console.error('Error:', error);

  // Render component.
  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6}>
          <Typography variant="h1" gutterBottom>
            Recital Info
          </Typography>
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
          {isError && (
            <Alert severity="error">
              There was a problem getting the recital info: {error?.error || error?.message}
            </Alert>
          )}
          {isSuccess && (
            <>
              {data.map((item) => (
                <DynamicComponentRenderer key={item.id} component={item.component} props={item.props} />
              ))}
            </>
          )}
        </Grid>
        <Grid xs={12} sm={6}>
          <Image src={recital} aspectRatio={1 / 1} alt="Recital Poster" />
        </Grid>
      </Grid>
      <Typography m={1}>
        If you have any questions about any of this information please{' '}
        <MuiLink component={RouterLink} to="/contact" color="textSecondary">
          contact us
        </MuiLink>
        .
      </Typography>
    </Box>
  );
};

// Export component.
export default RecitalInfo;
