/**
 * © 2024 Little Shilling, Inc. All rights reserved.
 * Shon Little
 * Created: 2024-02-06
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import {
  useTheme,
  useMediaQuery,
  Box,
  Unstable_Grid2 as Grid,
  Paper,
  Typography,
  Button,
  Link as MuiLink,
} from '@mui/material';

// Add local dependences.
import Icons from '../Common/Icons';
import releveStudiosBg from '../../assets/images/releve-studios-bg.png';
import studioOne from '../../assets/images/studio-one.png';

/**
 * Home component.
 * @example
 * return (
 *   <Home />
 * )
 * @returns {React.ReactElement} component.
 */
const Home = () => {
  // Set banner array.
  const banner = [
    { honor: '“Voted Best Dance Studio in the Valley”', from: 'Ventura Blvd Magazine' },
    { honor: '“Studio of the Year”', from: 'Dance Studio Owners Association' },
    { honor: '“Small Business of the Year”', from: 'San Fernando Valley United Chambers of Commerce' },
    { honor: '“Customer Experience Studio of the Year”', from: 'Dance Studio Owners Association' },
  ];

  // Set state hooks.
  const [count, setCount] = useState(0);

  // Set effect hooks.
  useEffect(() => {
    const interval = setInterval(() => {
      const newCount = count + 1 >= banner.length ? 0 : count + 1;
      setCount(newCount);
    }, 10000);
    return () => clearInterval(interval);
  }, [count]);

  // Get theme.
  const theme = useTheme();

  // Use the useMediaQuery hook with the theme's breakpoints.
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Set component styles.
  const styles = {
    root: {
      [theme.breakpoints.up('md')]: {
        bgcolor: theme.palette.primary.main,
        backgroundImage: `url(${releveStudiosBg})`,
        width: '100%',
        height: 'calc(100vh - 95px - 101.3px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundAttachment: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1,
        paddingTop: '16px',
        overflowX: 'hidden',
      },
    },
    paper: {
      padding: theme.spacing(3, 2),
      color: theme.palette.secondary.contrastText,
      background: theme.palette.secondary.main,
    },
    button: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      ':hover': {
        bgcolor: theme.palette.common.white,
      },
    },
    avatar: {
      background: theme.palette.primary.main,
    },
    card: {
      marginTop: theme.spacing(2),
    },
    best: {
      fontSize: '1.82rem',
    },
    newHome: {
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.common.black,
      textShadow: '-1px -1px 2px #ffffff, 0 0 25px #ffffff, 0 0 5px #ffffff',
    },
    phone: {
      fontSize: '1.5rem',
      fontWeight: 500,
      textShadow: '-1px -1px 2px #ffffff, 0 0 25px #ffffff, 0 0 5px #ffffff',
    },
    photoPaper: {
      position: 'relative',
    },
    photoCredit: {
      position: 'absolute',
      left: 2,
      color: theme.palette.common.white,
      fontSize: '0.5rem',
      [theme.breakpoints.down('md')]: {
        bottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        bottom: 100,
      },
    },
    glow: {
      textShadow: '-1px -1px 2px #ffffff, 0 0 25px #ffffff, 0 0 5px #ffffff',
    },
  };

  // Render component.
  return (
    <Box sx={styles.root}>
      <Grid container justifyContent="center">
        <Grid>
          <Paper sx={styles.paper}>
            <Typography align="center" gutterBottom sx={styles.best}>
              {banner[count].honor}
            </Typography>
            <Typography variant="body2" align="center">
              {banner[count].from}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Box mb={3}>
        <Grid container justifyContent="center">
          <Grid>
            <Button
              variant="contained"
              size="large"
              startIcon={<Icons iconName="Update" />}
              component={RouterLink}
              to="/schedule"
              sx={styles.button}
            >
              Click here for <br /> our schedule
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              size="large"
              startIcon={<Icons iconName="LocalOffer" />}
              component={RouterLink}
              to="/special-offer"
              sx={styles.button}
            >
              Click here for <br /> special offer
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              size="large"
              startIcon={<Icons iconName="WbSunny" />}
              component={RouterLink}
              to="/camps"
              sx={styles.button}
            >
              Click here for <br /> Summer Camps
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              size="large"
              startIcon={<Icons iconName="LocalActivity" />}
              component={RouterLink}
              to="/recital-info"
              sx={styles.button}
            >
              Click here for <br /> Recital Info
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="column" alignItems="center">
        <Grid>
          <Typography variant="h1" sx={styles.glow}>
            Relev&eacute; Studios
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            size="large"
            startIcon={<Icons iconName="PersonAdd" />}
            component={RouterLink}
            to="/register"
            sx={styles.button}
          >
            Click here to register
          </Button>
        </Grid>
      </Grid>
      {isMobile && (
        <Box my={3}>
          <Paper elevation={3} sx={styles.photoPaper}>
            <Image src={studioOne} alt="Studio One" />
            <Typography variant="body2" sx={styles.photoCredit}>
              Photography by Amy Bartlam
              <br />
              Interior Architectural Design and Construction by Four Point Design Build Inc
            </Typography>
          </Paper>
        </Box>
      )}
      <Box my={3}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid xs={12}>
            <MuiLink component={RouterLink} to="/location">
              <Typography align="center" sx={styles.newHome}>
                Located in the {isMobile && <br />} Northridge Plaza Shopping Center
              </Typography>
              <Typography align="center" sx={styles.newHome}>
                (with Target &amp; Kohl&apos;s)
              </Typography>
              <Typography align="center" sx={styles.newHome}>
                8766 Corbin Ave, Northridge, CA 91324
              </Typography>
            </MuiLink>
          </Grid>
          <Grid xs={12}>
            <MuiLink href="tel:+1-818-654-4140" color="inherit">
              <Typography align="center" sx={styles.phone}>
                Call with Questions: {isMobile && <br />}
                1-818-654-4140
              </Typography>
            </MuiLink>
          </Grid>
        </Grid>
      </Box>
      {!isMobile && (
        <Box>
          <Typography variant="body2" sx={styles.photoCredit}>
            Photography by Amy Bartlam
            <br />
            Interior Architectural Design and Construction by Four Point Design Build Inc
          </Typography>
        </Box>
      )}
    </Box>
  );
};

// Export component.
export default Home;
